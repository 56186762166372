import { Typography } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import { useRacer } from '../services/RacerService'

type Props = {
  eventId: string
  racerId: string
}

export const RacerNames: FunctionComponent<Props> =
  ({ eventId, racerId }) => {
    const racer = useRacer(eventId, racerId)
    if (!racer) { return null }
    return (
      <Fragment>
        <Typography sx={{fontSize: 18}}>{racer.carName}</Typography>
        <Typography variant="body2" sx={{color: 'text.secondary', fontStyle: 'italic'}} >{racer.firstName} {racer.lastName}</Typography>
      </Fragment>
    )
  }
