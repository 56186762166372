import { Box } from '@mui/material'
import { FunctionComponent } from 'react'
import { StyledName } from './StyledName'

export const Footer: FunctionComponent<{}> =
  () => {
    return (
      <Box sx={{
        padding: '20px',
        display: 'flex',
        flexFlow: 'wrap',
        justifyContent: 'center',
        backgroundColor: 'white'
      }}>
        <StyledName />
      </Box>
    )
  }