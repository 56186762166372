import { ArrowDropDown, Favorite } from '@mui/icons-material'
import { Avatar, Button, Dialog, Divider, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { FunctionComponent, useRef, useState } from 'react'
import { useAuthState, useLogout } from '../services/FirebaseAuthService'
import { ReactiveButton } from './ReactiveLink'
import { Login } from './Login'
import { useNavigate } from 'react-router-dom'

type Props = {
  showLogin?: boolean
}

export const AuthState: FunctionComponent<Props> =
  ({ showLogin }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [ open, setOpen ] = useState(false)
    const auth = useAuthState()
    const logout = useLogout()
    const nav = useNavigate()

    if (auth) {
      return (
        <>
          <Button onClick={() => setOpen(true)} sx={{color:'black'}}>
            <Avatar src={auth.photo} ref={ref} />
            <ArrowDropDown />
          </Button>
          <Menu
            anchorEl={ref.current}
            open={open}
            onClose={() => setOpen(false)}
            sx={{ zIndex: 2000 }}
          >
            <MenuItem>
              <Stack>
                <Typography variant="caption">Signed in as</Typography>
                <Typography>{auth.name}</Typography>
                <Typography variant="caption">{auth.email}</Typography>
              </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { nav('/favorites') }}>
              <Favorite sx={{ pr: 1, color: 'red' }} /> Favorites
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => { logout(); setOpen(false) }}>
              Logout
            </MenuItem>
          </Menu>
        </>
      )
    } else if (showLogin) {
      return (<LoginFromHeader />)
    } else {
      return null
    }
  }

const LoginFromHeader: FunctionComponent<{}> =
  () => {
    const [ open, setOpen ] = useState(false)

    return (
      <>
        <ReactiveButton onClick={() => setOpen(true)}>Log in</ReactiveButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <Login>Login to see your associated events & create a new event</Login>
        </Dialog>
      </>
    )
  }
