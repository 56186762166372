import { RacerResult } from '@derbytronics/models'
import { SharedCollectionListenerService, useSharedHook } from './SharedListenerService'

class RacerResultService extends SharedCollectionListenerService<RacerResult> {

  private static singletons: Map<string, RacerResultService> = new Map()

  static for(eventId: string, racerId: string): RacerResultService {
    const key = `${eventId}////${racerId}`
    let found = this.singletons.get(key)
    if (!found) {
      found = new RacerResultService(eventId, racerId)
      this.singletons.set(key, found)
    }
    return found
  }

  private constructor(readonly eventId: string, readonly racerId: string) {
    super(`events/${eventId}/racers/${racerId}/results`)
  }

}

export function useRacerResults(eventId: string, racerId: string): RacerResult[] | undefined {
  return useSharedHook(
    RacerResultService.for(eventId, racerId),
    (data) => Object.values(data),
    [ eventId, racerId ]
  )
}

export function useRacerResult(eventId: string, racerId: string, heatOrder: number): RacerResult | undefined {
  return useSharedHook(
    RacerResultService.for(eventId, racerId),
    (data) => Object.values(data).find(r => r.heatOrder === heatOrder),
    [ eventId, racerId ]
  )
}
