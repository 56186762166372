import { Box, LinearProgress, SxProps, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom';
import { useHeats } from '../services/HeatsService';

export const HeatProgressBar: FunctionComponent<{ sx?: SxProps<Theme> }> =
  ({ sx }) =>{
    const params = useParams()
    const eventId = params.eventId as string
    const heats = useHeats(eventId)
    const number = heats?.reduce((c, h) => c += +h.done, 0) || 0
    const total = heats?.length || 0

    return <ExplicitHeatProgressBar  number={number} total={total} sx={sx}/>
  }

type SpecificProps = {
  number: number
  sx?: SxProps<Theme>
}

export const SpecificHeatProgressBar: FunctionComponent<SpecificProps> =
  (props) => {
    const params = useParams()
    const eventId = params.eventId as string
    const heats = useHeats(eventId)
    const total = heats?.length || 0

    return <ExplicitHeatProgressBar total={total} {...props} />
  }

type ExplicitProps = {
  number: number
  total: number
  sx?: SxProps<Theme>
}

export const ExplicitHeatProgressBar: FunctionComponent<ExplicitProps> =
  ({ number, total, sx }) => {
    const pct = (total > 0) ? 100 * number / total : 0
    const variant = (total > 0) ? 'determinate' : 'indeterminate'

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', ...sx }}>
        <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
          <LinearProgress variant={variant} sx={{ height: 12, borderRadius: 12 }} value={pct} />
          { total > 0 &&
            <Box 
              sx={{
                position: 'absolute',
                left: 0, right: 0, top: 0, bottom: 0,
                display: 'flex',
                alignItems: 'center',
                flexFlow: 'column',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h4">Heat {number + 1} / {total}</Typography>
            </Box>
          }
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="h4">{`${Math.round(pct)}%`}</Typography>
        </Box>
      </Box>
    )
  }