import { GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useEventId } from './EventService'
import { auth, FunctionsRootUrl } from './FirebaseConfig'
import { useUser } from './UsersService'

const LocalStorageKey = 'firebase_auth'

export type AuthInfo = {
  email: string
  token: string
  expires: string
  name?: string
  photo?: string
}

// State information shared across all listeners
let info: AuthInfo | undefined = undefined
const authListeners: { [id: number]: () => void } = {}
let nextListenerId: number = 0

// Initialize from Local Storage
const stored = localStorage.getItem(LocalStorageKey)
if (stored) {
  try {
    info = JSON.parse(stored)
  } catch {
    localStorage.removeItem(LocalStorageKey)
  }
}

async function hydrate(u: User, force: boolean): Promise<void> {
  try {
    const result = await u.getIdTokenResult(force)

    info = {
      email: (u.email as string),
      token: result.token,
      expires: result.expirationTime,
      name: u.displayName || undefined,
      photo: u.photoURL || undefined
    }

    localStorage.setItem(LocalStorageKey, JSON.stringify(info))

    // Make sure to call all the auth listeners to update them that auth info changed
    Object.values(authListeners).forEach(l => l())
  } catch (e) {
    // Assuming the token is totally invalid now, force logout
    await auth.signOut()
    info = undefined
  }
}

async function login(): Promise<boolean> {
  if (info) {
    return false
  } else if (auth.currentUser) {
    await hydrate(auth.currentUser, false)
    return true
  } else {
    const uc = await signInWithPopup(auth, new GoogleAuthProvider())
    
    const token = await uc.user.getIdToken()
    await fetch(`${FunctionsRootUrl}/web/hydrateClaims`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      }
    })
    await hydrate(uc.user, true)
    return true
  }
}

export function useAuthState(): AuthInfo | undefined {
  const [ , setX ] = useState(0)

  useEffect(() => {
    const listenerId = nextListenerId++
    authListeners[listenerId] = () => { setX( Math.random() ) }
    return () => { delete authListeners[listenerId] }
  }, [])

  return info
}

export function useLogin(): () => Promise<boolean> {
  return () => login()
}

export function useLogout(): () => void {
  return () => {
    if (info) {
      auth.signOut()
      info = undefined
      localStorage.removeItem(LocalStorageKey)
      // Make sure to call all the auth listeners to update them that auth info changed
      Object.values(authListeners).forEach(l => l())
    }
  }
}

export function useAuth(): AuthInfo | undefined {
  const [ , setX ] = useState(0)

  useEffect(() => {
    login().then(changed => {
      if (changed) { setX(Math.random()) }
    })
  }, [ ])

  return info
}

export function useRequiredAdminAuth(): AuthInfo | undefined {
  const auth = useAuth()
  const eventId = useEventId()
  const user = useUser(eventId, auth?.email)

  return (auth && user && user.admin) ? auth : undefined
}