import { Box, Typography, useTheme } from '@mui/material'
import { Fragment, FunctionComponent } from 'react'
import { useEvent } from '../services/EventService'
import { useLastHeat, useNextHeat } from '../services/HeatsService'
import { Place } from './Place'
import { RacerNames } from './RacerNames'
import { Duration } from './Duration'

type Props = {
  eventId: string
  upcomingCount: number
}

export const HeatQuickView: FunctionComponent<Props> =
  ({ eventId, upcomingCount }) => {
    const event = useEvent(eventId)
    const theme = useTheme()
    const last = useLastHeat(eventId)
    const upcoming = useNextHeat(eventId, upcomingCount)

    if (!event) { return (<Box></Box>) }
    const lanes = Object.keys([...new Array(event.lanes)]).map(l => +l)

    return (
      <Box display="grid" gridTemplateRows="repeat(7, 1fr)" gridTemplateColumns="180px 75px auto 100px auto auto" alignItems="center" height="100%">
        { last && <Typography gridColumn="2/5" gridRow={1} variant="h4">Heat {last.order+1}</Typography> }
        { upcoming.map((next, i) => <Typography key={next.order} gridColumn={5+i} gridRow={1} variant="h4">Heat {next.order+1}</Typography>) }
        { lanes.map(l => (
            <Fragment key={l}>
              <Typography gridColumn={1} gridRow={l+2} variant="h3">Lane {l+1}</Typography>
              { l < event.lanes - 1 && <Box gridRow={`${l+2}`} height="0px" alignSelf="end" gridColumn="1/7" borderBottom={`1px solid ${theme.palette.divider}`} width="100%"/> }
              { last && <Fragment>
                <Box gridRow={`${l+2}`} gridColumn={2} textAlign="center"><Place value={last.places?.[l]}/></Box>
                <Box gridRow={`${l+2}`} gridColumn={3}><RacerNames eventId={eventId} racerId={last.racers[l] as string}/></Box>
                <Box gridRow={`${l+2}`} gridColumn={4}><pre style={{ fontSize: 15 }}><Duration value={last.result?.[l]} /></pre></Box>
              </Fragment>}
              { upcoming.map((next, i) => (<Box key={next.order} gridRow={`${l+2}`} gridColumn={5+i}><RacerNames eventId={eventId} racerId={next.racers[l] as string}/></Box>)) }
            </Fragment>
          ))
        }
      </Box>
    )
  }