import { Launch } from '@mui/icons-material'
import { Box, IconButton, Paper, Skeleton, Stack, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import { ElementType, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { useEvent } from '../services/EventService'
import { mediaUrl, Size50x50 } from '../services/MediaUrlService'
import { useRacerResults } from '../services/RacerResultsService'
import { RacerWithId, useBrackets, useRacerList } from '../services/RacerService'
import { Place } from './Place'
import { sx } from './RankBadgeIcon'
import { Duration } from './Duration'

const ResultListSkeleton: FunctionComponent<{}> =
  () => (
    <Paper>
      <Skeleton />
    </Paper>
  )

type PopulatedProps = {
  racers: RacerWithId[]
  eventId: string
  hideHeader: boolean
  hideOverallPlace: boolean
  hideBracketPlace: boolean
  compact: boolean
}

const PopulatedResultsList: FunctionComponent<PopulatedProps> =
  ({ racers, eventId, hideHeader, compact, hideBracketPlace, hideOverallPlace }) => {
    const large = useMediaQuery('(min-width: 1000px)')
    const tooSmall = useMediaQuery('(max-width: 425px')
    const event = useEvent(eventId)
    const lanes = Object.keys([...new Array(event?.lanes || 6)]).map(k => +k)
  
    return (
      <Table size="small">
        { large && !hideHeader &&
          <TableHead >
            <TableRow>
              <TableCell colSpan={2} align="center"><Typography>Place</Typography></TableCell>
              { !tooSmall && <TableCell rowSpan={2}><Typography variant="h6"></Typography></TableCell> }
              <TableCell rowSpan={2}><Typography variant="h6">Name</Typography></TableCell>
              <TableCell rowSpan={2} align="center"><Typography variant="h6">Average Time</Typography></TableCell>
              { large && <TableCell colSpan={6} align="center"><Typography>Lane Times</Typography></TableCell> }
            </TableRow>
            <TableRow>
              <TableCell align="center">Overall</TableCell>
              <TableCell align="center">Bracket</TableCell>
              { large && lanes.map(i => <TableCell key={i} align="center">{i + 1}</TableCell>) }
            </TableRow>
          </TableHead>
        }
        <TableBody>
          { racers.map(r => <RacerResultsRow key={r.id} eventId={eventId} racer={r} large={large} tooSmall={tooSmall} lanes={lanes} compact={compact} hideBracketPlace={hideBracketPlace} hideOverallPlace={hideOverallPlace}/>)}
        </TableBody>
      </Table>
    )
  }

type RacerResultsRowProps = {
  eventId: string
  racer: RacerWithId
  large: boolean
  tooSmall: boolean
  lanes: number[]
  compact: boolean
  hideOverallPlace: boolean
  hideBracketPlace: boolean
}

const RacerResultsRow: FunctionComponent<RacerResultsRowProps> =
  ({ eventId, racer, large, tooSmall, lanes, compact, hideBracketPlace, hideOverallPlace }) => {
    const results = useRacerResults(eventId, racer.id)
    return (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        { !hideOverallPlace && <TableCell align="center"><Place value={racer.overallPlace} /></TableCell> }
        { !hideBracketPlace && <TableCell align="center"><Place value={racer.bracketPlace} /></TableCell> }
        { !tooSmall && !compact && <TableCell><img style={sx} alt="Car" src={mediaUrl(racer.media, Size50x50)} /></TableCell> }
        <TableCell>
          <Stack
            direction="row"
            component={Link}
            to={`/event/${eventId}/racer/${racer.id}`}
            sx={{ color: 'inherit', textDecoration: 'inherit' }}
            justifyContent="space-between"
          >
            <Box>
              <Typography sx={{fontSize: 18}}>{racer.carName}</Typography>
              <Typography variant="body2" sx={{color: 'text.secondary', fontStyle: 'italic'}} >{racer.firstName} {racer.lastName}</Typography>
            </Box>
            <IconButton><Launch /></IconButton>
          </Stack>
        </TableCell>
        <TableCell align="center">
          <pre style={{ fontSize: 15 }}><Duration value={racer.averageTime} /></pre>
        </TableCell>
        { large && results && !compact && lanes.map(i =>
          <TableCell key={i} align="center">
            <pre style={{ fontSize: 15 }}><Duration value={results.find(r => r.lane === i)?.time} /></pre>
          </TableCell>
        )}
      </TableRow>
    )
  }

type OverallProps = {
  eventId: string
  brackets?: string[]
  lastNames?: string[]
}

export const OverallResults: FunctionComponent<OverallProps> =
  ({ eventId, brackets, lastNames }) => {
    const theme = useTheme()

    return (
      <ResultsList
        eventId={eventId}
        brackets={brackets}
        lastNames={lastNames}
        component={Paper}
        sx={{ width: `calc(100% - ${theme.spacing(8)})`, m: 2, p: 2, flex: "1 1" }}
      />
    )
  }

type ResultsListProps = {
  eventId: string
  brackets?: string[]
  lastNames?: string[]
  hideHeader?: boolean
  hideOverallPlace?: boolean
  hideBracketPlace?: boolean
  compact?: boolean
  component?: ElementType<any>
  sx?: SxProps<Theme>
}

export const ResultsList: FunctionComponent<ResultsListProps> =
  ({ eventId, brackets, lastNames, hideHeader, hideOverallPlace, hideBracketPlace, compact, component, sx }) => {
    const racers = useRacerList(eventId)
    const filtered = !racers ? undefined :
      racers.filter(r => !brackets || brackets.length === 0 || brackets.indexOf(r.bracket) > -1)
            .filter(r => !lastNames || lastNames.length === 0 || lastNames.indexOf(r.lastName) > -1)

    if (!filtered) {
      return (<ResultListSkeleton />)
    } else {
      return (
        <TableContainer component={component || 'div'} sx={sx}>
          <PopulatedResultsList
            racers={filtered}
            eventId={eventId}
            hideOverallPlace={!!hideOverallPlace}
            hideBracketPlace={!!hideBracketPlace}
            hideHeader={!!hideHeader}
            compact={!!compact}
          />
        </TableContainer>
      )
    }
  }

type BracketedResultsListProps = {
  eventId: string
}

export const BracketedResultsList: FunctionComponent<BracketedResultsListProps> =
  ({ eventId }) => {
    const brackets = useBrackets(eventId)
    return (
      <Box>
        { brackets && brackets.map(b => (
          <Box key={b}>
            <Typography variant="h5">{b}</Typography>
            <ResultsList eventId={eventId} brackets={[b]} hideHeader compact hideOverallPlace />
          </Box>
        )) }
      </Box>
    )
  }
