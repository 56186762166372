import { Box, Button, CircularProgress } from '@mui/material'
import { FunctionComponent } from 'react'

type Props = {
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
  loading: boolean
  submit: () => Promise<any>
  onDone?: () => any | void
  disabled?: boolean
}

export const MutationButton: FunctionComponent<Props> =
  ({ color, loading, submit, children, onDone, disabled }) => {
    async function onClick() {
      if (!loading) { await submit() }
      if (onDone) { onDone() }
    }

    return (
      <Button variant="contained" color={color} onClick={onClick} disabled={disabled}>
        <Box position="relative">
          <Box sx={{ opacity: loading ? 0 : 1, transition: 'opacity 200ms' }}>
            { children }
          </Box>
          <Box position="absolute" sx={{ left: 0, right: 0, top: 0, bottom: 0, opacity: loading ? 1 : 0, transition: 'opacity 200ms' }}>
            <CircularProgress size={24} />
          </Box>
        </Box>
      </Button>
    )
  }