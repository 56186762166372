import { useState } from 'react'
import { AuthInfo, useAuth } from './FirebaseAuthService'

export type MutationFunction = (...args: any[]) => Promise<Response>

export function useMutation<F extends MutationFunction>(f: (auth: AuthInfo) => F): { loading: boolean, submit: (...args: Parameters<F>) => Promise<Response> } {
  const [ loading, setLoading ] = useState(false)
  const auth = useAuth()

  async function submit(...args: Parameters<F>): Promise<Response> {
    if (!auth) { throw new Error(`Can't mutate without a valid auth session`) }

    setLoading(true)
    const timer = new Promise<number>((resolve) => setTimeout(() => resolve(1), 1000))
    const action = f(auth)(...args)
    const result = await Promise.all([ timer, action ])
    setLoading(false)

    return result[1]
  }

  return { loading, submit }
}
