import { Event } from '@derbytronics/models'
import { Alert, Box, Button, Paper, Stack, Typography } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useResetEvent, useStartEvent, useUpdateEvent } from '../../services/EventService'
import { Form } from '../forms/Form'
import { DateFormField, NumberFormField, TextFormField } from '../forms/FormField'
import { HeatAssignmentView } from './HeatAssignmentView'

export const EventControl: FunctionComponent<{ eventId: string, event: Event }> =
  ({ eventId, event }) => {
    return (
      <Box display="flex" flex="row" flexWrap="wrap" alignItems="flex-start" justifyContent="centered" gap="30px">
        <Stack gap={3}>
          <EditEventForm  eventId={eventId} event={event} />
          { event.started && (<ResetEvent eventId={eventId} />) }
          { !event.started && (<StartEvent eventId={eventId} /> ) }
        </Stack>

        <Paper sx={{ p: 3 }} >
          <Typography variant="h4" sx={{ mb: 2 }}>Heat Assignments</Typography>
          <HeatAssignmentView eventId={eventId} lanes={event.lanes} />
        </Paper>
      </Box>
    )
  }

const EditEventForm: FunctionComponent<{ eventId: string, event: Event }> =
  ({ eventId, event }) => {
    const { loading, submit } = useUpdateEvent(eventId)
    return (
      <Form
        open={true}
        loading={loading}
        title="Edit Event Details"
        initialData={{
          name: { value: event.name },
          date: { value: event.date },
          lanes: { value: event.lanes }
        }}
        submit={(data) => submit(data.name, data.date, data.lanes)}
        variant="paper"
        sx={{ p: 3, maxWidth: 500 }}
      >
        <TextFormField id="name" label="Name" required />
        <DateFormField id="date" label="Date" required />
        <NumberFormField id="lanes" label="Lanes" required disabled={event.started} />
      </Form>
    )
  }

export const StartEvent: FunctionComponent<{eventId: string}> =
  ({ eventId }) => {
    const { loading, submit } = useStartEvent(eventId)

    return (
      <Form
        open={true}
        loading={loading}
        title="Control Event"
        submit={submit}
        submitText="Start"
        variant="paper"
        sx={{ p: 3, maxWidth: 500 }}
      >
        <Typography>
          This event has not yet been started. Starting the event will assign the cars/racers to
          heats.
        </Typography>
      </Form>
    )
  }


export const ResetEvent: FunctionComponent<{eventId: string}> =
  ({ eventId }) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useResetEvent(eventId)

    return (
      <>
        <Paper sx={{ p: 3, maxWidth: 500 }} >
          <Typography variant="h4" sx={{ mb: 2 }}>Control Event</Typography>
          <Typography>
            This event has been started. You <i>can</i> reset this entire event but you will lose any
            race results and heat assignments. This is required if you want to go back and modify the
            racer registerations. If you need to just revoke a single heat, use that tab...
          </Typography>
          <Box textAlign="right" sx={{ mt: 1 }}>
            <Button variant="contained" color="error" onClick={() => setOpen(true)}>Restart</Button>
          </Box>
        </Paper>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title="Restart Event?"
          submit={submit}
          submitText="Restart"
          destructive
        >
          <Alert severity="warning">
            Resetting an event will delete all race results and heat assignments.
            Do not do this unless you really know what you are doing!
          </Alert>
        </Form>
      </>
    )
  }