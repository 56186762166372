import { Paper, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { AdminRacerList } from '../components/admin/AdminRacerList'
import { ApiUsersList } from '../components/admin/ApiUsersList'
import { EventControl } from '../components/admin/EventControl'
import { ResetHeats } from '../components/admin/ResetHeats'
import { UsersList } from '../components/admin/UsersList'
import { EventCentricView } from '../components/Header'
import { Login } from '../components/Login'
import { SimpleTabs } from '../components/SimpleTabs'
import { useCurrentEvent } from '../services/EventService'
import { useAuthState, useRequiredAdminAuth } from '../services/FirebaseAuthService'

export const AdminPage: FunctionComponent<{}> =
  () => {
    const { event, eventId } = useCurrentEvent()
    const auth = useAuthState()
    const isAdmin = !!useRequiredAdminAuth()

    if (!event || !auth) {
      return (
        <EventCentricView>
          <Login>
            Login to access admin controls for your associated events. Derbytronics
            uses Google for authentication. A Google account is required for admin
            controls.
          </Login>
        </EventCentricView>
      )
    } else if (!isAdmin) {
      return (
        <EventCentricView>

          <Paper sx={{ maxWidth: 500, ml: 'auto', mr: 'auto', p: 2 }}>
            <Typography variant="h4">Not an Admin</Typography>
            <Typography sx={{ mt: 2, mb: 2 }}>
              You do not have admin rights to this event. Please contact your event administrator
              if you think this is an error. Thanks and happy racing!
            </Typography>
          </Paper>
        </EventCentricView>
      )
    }

    return (
      <EventCentricView>
        <SimpleTabs
          tabs={[
            { name: 'Racers', component: () => <AdminRacerList eventId={eventId} /> },
            { name: 'Users', component: () => <UsersList eventId={eventId} /> },
            { name: 'API Keys', component: () => <ApiUsersList eventId={eventId} /> },
            { name: 'Manage Event', component: () => <EventControl eventId={eventId} event={event} /> },
            { name: 'Reset Heat', component: () => <ResetHeats eventId={eventId} /> }
          ]}
        />
      </EventCentricView>
    )
  }
