import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useAuthState } from '../services/FirebaseAuthService'
import { HomeHeader } from './HomePage'
import { Footer } from '../components/Footer'
import { Login } from '../components/Login'
import { useFavorites } from '../services/FavoritesService'
import { Link } from 'react-router-dom'

export const FavoritesPage: FunctionComponent<{}> =
  () => {
    const auth = useAuthState()

    return (
      <>
        <HomeHeader />
        <Box pt={2} pb={2}>
          { !auth && <Login>Login to see and save your favorites!</Login> }
          { auth && <FavoritesList /> }
        </Box>
        <Footer />
      </>
    )
  }

export const FavoritesList: FunctionComponent<{}> =
  () => {
    const { favorites } = useFavorites()

    if (!favorites) { return null }
    
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="body1" fontWeight="bold">Event</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { favorites.events.map(e => (
            <TableRow><TableCell>
              <Link to={`/event/${e.eventId}`}>
                <Typography variant="body1" fontWeight="bold">{ e.eventId }</Typography>
              </Link>
            </TableCell></TableRow>
          )) }
          
        </TableBody>
      </Table>
    )
  }
