import { Box, Typography } from '@mui/material'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

type ReactiveClickableProps = {
  to?: string
  onClick?: React.MouseEventHandler<HTMLElement>
  component?: React.ElementType<any>
}

export const ReactiveClickable: FunctionComponent<PropsWithChildren<ReactiveClickableProps>> =
  ({ to, onClick, component, children }) => {
    return (
      <Box
        component={component}
        onClick={onClick}
        {...{to}}
        sx={{
          color: 'inherit',
          ml: 2,
          mr: 2,
          textDecoration: 'none',
          position: 'relative',
          cursor: 'pointer',
          '::after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            transform: 'scaleX(0)',
            height: '2px',
            bottom: 0,
            left: 0,
            backgroundColor: (theme) => theme.palette.secondary.main,
            transformOrigin: 'bottom right',
            transition: 'transform 0.25s ease-out',
          },
          ':hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left'
          }
        }}>
        <Typography variant="h5">{children}</Typography>
      </Box>
    )
  }

export type ReactiveLinkProps = { to: string }

export const ReactiveLink: FunctionComponent<PropsWithChildren<ReactiveLinkProps>> =
  ({ to, children }) => (<ReactiveClickable component={Link} to={to} children={children} />)

export type ReactiveButtonProps = { onClick: React.MouseEventHandler<HTMLElement> }

export const ReactiveButton: FunctionComponent<PropsWithChildren<ReactiveButtonProps>> =
  ({ onClick, children }) => (<ReactiveClickable component="a" onClick={onClick} children={children} />)
