import { EventAssociation } from '@derbytronics/models'
import { FunctionsRootUrl } from './FirebaseConfig'
import { useMutation } from './UseMutation'
import { useEffect, useState } from 'react'
import { useAuth } from './FirebaseAuthService'

export type Favorites = { events: EventAssociation[] }

export function useFavorites(): { loading: boolean, favorites?: Favorites } {
  const [ favorites, setFavorites ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const auth = useAuth()

  useEffect(() => {
    if (favorites || loading || !auth) {
      return
    }

    setLoading(true)
    fetch(`${FunctionsRootUrl}/web/favorites`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json'
      }
    }).then((resp) => resp.json())
      .then((body) => setFavorites(body))
  }, [ auth, loading, favorites ])

  return { loading, favorites: favorites || undefined }
}

export const useAddFavorite =
  () =>
    useMutation((auth) =>
      async (eventId) => {
        return await fetch(`${FunctionsRootUrl}/web/favorites/${eventId}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          }
        })
      }
    )

export const useDeleteFavorite =
  () =>
    useMutation((auth) =>
      async (eventId) => {
        return await fetch(`${FunctionsRootUrl}/web/favorites/${eventId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          }
        })
      }
    )
