import { AppBar, Box, Button, Paper, TextField, Toolbar, Typography } from '@mui/material'
import { FormEvent, FunctionComponent, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from 'react-router-dom'
import StatsImage from '../assets/screenshots/stats.png'
import { AuthState } from '../components/AuthState'
import { CreateEventButton } from '../components/CreateEvent'
import { Footer } from '../components/Footer'
import { ReactiveLink } from '../components/ReactiveLink'
import { StyledName } from '../components/StyledName'

const CarouselData = [
  { img: StatsImage, text: 'Project Realtime Race Results' },
  { img: StatsImage, text: 'Compatible with Micro Wizard® Timer or make your own with available plans (3d printer and soldering skills required).' },
  { img: StatsImage, text: 'Results and schedules for particpants' },
  { img: StatsImage, text: 'Easy setup for race organizers & particpants' },
  { img: StatsImage, text: 'Tools for race-day operations' }
]

export const HomeHeader: FunctionComponent<{}> =
 () => (
    <AppBar position="relative" sx={{ zIndex: 1500, pt: 2, pb: 2 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledName />
        </Box>
        <Box sx={{
          display: { xs: 'none', md: 'flex' },
          alignSelf: 'center'
        }}>
          <ReactiveLink to="/join">Join Event</ReactiveLink>
          <ReactiveLink to="/solutions">Solutions</ReactiveLink>
          <ReactiveLink to="/hardware">Hardware</ReactiveLink>
          <ReactiveLink to="/docs">Docs</ReactiveLink>
        </Box>
        <Box sx={{ display: 'flex', alignSelf: 'center' }}>
          <AuthState showLogin />
          <CreateEventButton />
        </Box>
      </Toolbar>
    </AppBar>
  )

export const HomePage: FunctionComponent<{}> =
  () => {
    const [eventId, setEventId] = useState<string | undefined>()
    const navigate = useNavigate()

    function gotoEvent(event: FormEvent<HTMLFormElement>) {
      event.preventDefault()
      event.stopPropagation()
      navigate(`/event/${eventId}`)
    }

    return (
      <div>
        <HomeHeader />
        <Box display="flex"
             flexDirection="column"
             alignItems="center"
             width="100%"
             sx={{ pt: 2, color: 'white', backgroundColor: (theme) => theme.palette.primary.light }}
        >
          <Typography variant="h1" m={2}>
            All-in-one race management.
          </Typography>
          <Typography variant="subtitle1" m={2}>
            Running your Pinewood Derby efficiently, intuitivly, and completely digital.
          </Typography>

          <Paper sx={{ m: (theme) => `${theme.spacing(2)} auto`, maxWidth: '800px', width: '95%' }}>
            <form onSubmit={(e) => gotoEvent(e)}>
              <Box display="flex" flexDirection="row" flexWrap="nowrap" p={2}>
                <TextField id="eventId" label="Enter Event Id" value={eventId} onChange={(e) => setEventId(e.target.value)} fullWidth/>
                <Button variant="contained" size="large" sx={{ml: 2}} type="submit">Go!</Button>
              </Box>
            </form>
          </Paper>

          <Typography variant="subtitle1" m={2} maxWidth="800px" >
            Tired of complicated setup, confusing outdated software, and trying to find race organizers who
            know how to set things up? Me too. Derbytronics exists in the hope that nobody ever has to have
            a tough race day. Spend your time creating memories with your scouts and not fretting over setup.
          </Typography>

          <Carousel animation="slide" stopAutoPlayOnHover autoPlay interval={8000} sx={{ maxWidth: '800px', width: '95%', m: 2 }} >
            {
              CarouselData.map(cd => (
                <Paper sx={{ p: 2 }} >
                  <Typography variant="body2" fontStyle="italic" >{cd.text}</Typography>
                  <Paper component="img" src={cd.img} sx={{ maxWidth: '800px', width: '95%', m: 2 }} />
                </Paper>
              ))
            }
          </Carousel>
        </Box>

        <Footer />
      </div>
    ) 
  }
