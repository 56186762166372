import { Event } from '@derbytronics/models'
import { useParams } from 'react-router-dom'
import { SharedDocumentListenerService, useSharedHook } from './SharedListenerService'
import { useMutation } from './UseMutation'
import { FunctionsRootUrl } from './FirebaseConfig'

class EventService extends SharedDocumentListenerService<Event> {

  private static singletons: { [eventId: string]: EventService } = {}

  static for(eventId: string): EventService {
    if (!(eventId in this.singletons)) {
      this.singletons[eventId] = new EventService(eventId)
    }
    return this.singletons[eventId]
  }

  private constructor(readonly eventId: string) {
    super(`events/${eventId}`)
  }

}

export function useEvent(eventId: string): Event | undefined {
  return useSharedHook(
    EventService.for(eventId),
    (data) => data,
    [ eventId ]
  )
}

export function useEventId(): string {
  const params = useParams()
  return params.eventId as string
}

export function useCurrentEvent(): { eventId: string, event: Event | undefined } {
  const eventId = useEventId()
  return { eventId, event: useEvent(eventId) }
}

export const useUpdateEvent =
  (eventId: string) =>
    useMutation((auth) => 
      async (name: string, date: string, lanes: number) => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, date, lanes })
        })
      }
    )


export const useCreateEvent =
  () =>
    useMutation((auth) => 
      async (name: string, date: string, lanes: number) => {
        return await fetch(`${FunctionsRootUrl}/web/events`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name, date, lanes })
        })
      }
    )

export const useStartEvent =
  (eventId: string) =>
    useMutation((auth) => 
      async () => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/start`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        })
      }
    )

export const useResetEvent =
  (eventId: string) =>
    useMutation((auth) => 
      async () => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/reset`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        })
      }
    )