import { Box, Paper, Stack, Typography, useTheme } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { SpecificHeatProgressBar } from '../components/HeatProgressBar'
import { useEventId } from '../services/EventService'
import { useHeatByOrder, useNextHeat } from '../services/HeatsService'
import { useRacerResult } from '../services/RacerResultsService'
import { useRacer } from '../services/RacerService'
import { Place } from '../components/Place'
import { mediaUrl, Size1000x1000 } from '../services/MediaUrlService'
import { useStatistic } from '../services/StatisticsService'
import { Duration } from '../components/Duration'

export const LiveBoard: FunctionComponent<{}> =
  () => {
    const eventId = useEventId()
    const [ heatNum, setHeatNum ] = useState(-1)
    const heat = useHeatByOrder(eventId, heatNum)
    const nextHeat = useNextHeat(eventId)
    const theme = useTheme()

    // On load when no heat is yet selected, select the next heat up to start
    useEffect(() => {
      if (heatNum === -1 && nextHeat.length === 1) {
        setHeatNum(nextHeat[0].order)
      }
    }, [ heatNum, nextHeat ])

    // Key listener to switch heats
    useEffect(() => {
      const handler = (event: KeyboardEvent) => {
        if (event.code === 'ArrowRight') {
          setHeatNum(heatNum + 1)
        } else if (event.code === 'ArrowLeft' && heatNum > 0) {
          setHeatNum(heatNum - 1)
        }
      }
      document.addEventListener('keydown', handler)
      return () => document.removeEventListener('keydown', handler)
    }, [ heatNum ])

    if (!heat) {
      return (null)
    }

    return (
      <Box
        display="grid"
        height={`calc(100vh - ${theme.spacing(6)})`}
        m={3}
        sx={{
          gridTemplateColumns: 'repeat(3,1fr)',
          gridTemplateRows: 'repeat(2,1fr) 80px',
          gridGap: theme.spacing(3),
          maxWidth: "100%"
        }}
        onKeyDown={() => { setHeatNum(heatNum + 1) }}
      >
        <RacerCardSkeleton index={0} eventId={eventId} heatNum={heatNum} racerId={heat.racers[0]} />
        <RacerCardSkeleton index={1} eventId={eventId} heatNum={heatNum} racerId={heat.racers[1]} />
        <RacerCardSkeleton index={2} eventId={eventId} heatNum={heatNum} racerId={heat.racers[2]} />
        <RacerCardSkeleton index={3} eventId={eventId} heatNum={heatNum} racerId={heat.racers[3]} />
        <RacerCardSkeleton index={4} eventId={eventId} heatNum={heatNum} racerId={heat.racers[4]} />
        <RacerCardSkeleton index={5} eventId={eventId} heatNum={heatNum} racerId={heat.racers[5]} />
        <SpecificHeatProgressBar number={heatNum} sx={{ gridColumnEnd: 'span 3' }}  />
      </Box>
    )
  }

const RacerCardSkeleton: FunctionComponent<{ index: number, eventId: string, heatNum: number, racerId: string | null }> =
  ({ index, eventId, heatNum, racerId }) => {
    const [ shown, setShown ] = useState(false)
    const racer = useRacer(eventId, racerId || '')
    const theme = useTheme()
    const result = useRacerResult(eventId, racerId || '', heatNum)
    const fastest = useStatistic(eventId)
    const fastestLane = useStatistic(eventId, index)

    const isFastest = fastest && result && fastest.fastestTime === result.time
    const isFastestLane = !isFastest && fastestLane && result && fastestLane.fastestTime === result.time
    
    useEffect(() => {
      setShown(false)
      const ref = setTimeout(() => setShown(true), index * 300 + 100)
      return () => clearTimeout(ref)
    }, [ heatNum, index ])

    return (
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "100%",
          transform: shown ? '' : 'translateX(100vw)',
          transition: shown ? 'transform 300ms ease-out' : '',
          overflow: 'visible'
        }}
      >
        <Stack m={0} position="relative" height="100%">
          <Box
            flex={3}
            sx={{
              backgroundImage: `url(${mediaUrl(racer?.media, Size1000x1000)})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: '4px 4px 0 0'
            }}
          />
          <Box flex={1} ml={2} mr={2}>
            <Typography variant="h1">{racer?.carName}</Typography>
            <Typography variant="h3" color="gray" fontStyle="italic">{racer?.firstName} {racer?.lastName}</Typography>
          </Box>
          <Box flex={1}>
            <Typography fontFamily="monospace" textAlign="center" fontSize="3.2rem"><Duration value={result?.time} default="-.---" /></Typography>
          </Box>
          
          <Typography
            variant="h1"
            sx={{
              color: 'white',
              position: 'absolute',
              left: theme.spacing(-1),
              top: theme.spacing(-0.5),
              transform: 'rotate(-25deg)',
              textShadow: `0 0 4px black, 0 0 4px black, 2px 2px 10px blue`
            }}
          >Lane {index + 1}</Typography>
          <Box
            sx={{
              position: 'absolute',
              right: theme.spacing(-1),
              top: theme.spacing(-0.5),
              transform: 'rotate(15deg)'
            }}
          >
            <Place value={result?.place} size="large"/>
          </Box>

          { (isFastest || isFastestLane) &&
            <Typography
              variant="h1"
              sx={{
                color: 'white',
                position: 'absolute',
                right: theme.spacing(0.5),
                bottom: theme.spacing(0.5),
                transform: 'rotate(-15deg)',
                textShadow: `0 0 4px black, 0 0 4px black, 2px 2px 10px red`
              }}
            >{isFastest ? 'Fastest Time!' : 'Best in Lane'}</Typography>
          }
        </Stack>
      </Paper>
    )
  }