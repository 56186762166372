import { Statistic } from '@derbytronics/models'
import { SharedCollectionListenerService, useSharedHook } from './SharedListenerService'

class StatisticsService extends SharedCollectionListenerService<Statistic> {

  private static singletons: { [eventId: string]: StatisticsService } = {}

  static for(eventId: string): StatisticsService {
    if (!(eventId in this.singletons)) {
      this.singletons[eventId] = new StatisticsService(eventId)
    }
    return this.singletons[eventId]
  }

  private constructor(readonly eventId: string) {
    super(`events/${eventId}/stats`)
  }
}

export function useStatistics(eventId: string): Statistic[] | undefined {
  return useSharedHook(
    StatisticsService.for(eventId),
    (data) => data ? Object.values(data) : undefined,
    [ eventId ]
  )
}

export function useStatistic(eventId: string, lane?: number): Statistic | undefined {
  const id = lane === undefined ? 'overall' : `lane_${lane}`
  return useSharedHook(
    StatisticsService.for(eventId),
    (data) => data?.[id],
    [ eventId, lane ]
  )
}