import { Box, Typography } from '@mui/material'
import { FunctionComponent } from 'react'

// const Places = [ '1st', '2nd', '3rd', '4th', '5th', '6th' ]
// const Backgrounds = [ 'linear-gradient(45deg, #785F02, #EDBD0F)',  ]
// const TextColors = [ 'white', , 'white' ]

type BasePlaceProps = {
  text: string
  background: string
  white?: boolean
}

const BasePlace: FunctionComponent<BasePlaceProps> =
  ({ text, background, white }) => {
    return (
      <Box sx={{
        background,
        color: white ? 'white' : 'black',
        width: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px'
      }} >
        <Typography variant="h3">{ text }</Typography>
      </Box> 
    )
  }

export type LivePlaceProps = {
  p: number | null | undefined
}

const Places: JSX.Element[] = [
  <BasePlace text="1st" background="linear-gradient(45deg, #190040, #3f48f2, #0aa9ff)" white />, // blue
  <BasePlace text="2nd" background="linear-gradient(45deg, #c20d00, #e62222, #ff5959)" white />, // red
  <BasePlace text="3rd" background="linear-gradient(45deg, #f5feff, #daeef0, #b2d8db)" />, // white
  <BasePlace text="4th" background="linear-gradient(45deg, #ffeaa6, #ffdf7f, #ffd861, #ffc524)" />, // yellow
  <BasePlace text="5th" background="linear-gradient(45deg, #145202, #4abf2a)" white />, // green
  <BasePlace text="6th" background="linear-gradient(45deg, #5c0000, #450505, #260000)" white />  // maroon
]

const Unknown = <BasePlace text="" background="" />

export const LivePlace: FunctionComponent<LivePlaceProps> =
  ({ p }) => {
    return typeof p === 'number' ? (Places[p - 1] || Unknown) : Unknown
  }
