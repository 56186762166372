import { UserPermissions } from '@derbytronics/models'
import { SharedCollectionListenerService, useSharedHook } from './SharedListenerService'
import { useMutation } from './UseMutation'
import { FunctionsRootUrl } from './FirebaseConfig'

export type ApiUserCollection = { [ key: string ]: UserPermissions }
export type UserPermissionWithKey = UserPermissions & { key: string }

class ApiUserService extends SharedCollectionListenerService<UserPermissions> {

  private static singletons: { [eventId: string]: ApiUserService } = {}

  static for(eventId: string): ApiUserService {
    if (!(eventId in this.singletons)) {
      this.singletons[eventId] = new ApiUserService(eventId)
    }
    return this.singletons[eventId]
  }

  private constructor(readonly eventId: string) {
    super(`events/${eventId}/apiUsers`)
  }
}

export function useApiUserList(eventId: string): UserPermissionWithKey[] {
  return useSharedHook(
    ApiUserService.for(eventId),
    (data) => Object.keys(data).map(key => ({ ...data[key], key })),
    [ eventId ]
  ) || []
}

export const useCreateApiUser =
  (eventId: string) =>
    useMutation((auth) => 
      async (key: string) => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/apiUsers`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ key })
        })
      }
    )

export const useDeleteApiUser =
  (eventId: string, key: string) =>
    useMutation((auth) => 
      async () => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/apiUsers`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ key })
        })
      }
    )
