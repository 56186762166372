import { AuthInfo } from './FirebaseAuthService'
import { FunctionsRootUrl } from './FirebaseConfig'
import { useMutation } from './UseMutation'

export type UpdateRacerPayload = {
  firstName: string
  lastName: string
  carName: string
  bracket: string
  label?: string
}

function extract(eventId: string, auth: AuthInfo, payload: UpdateRacerPayload, racerId?: string): { url: string, headers: HeadersInit, body: string } {
  const base = `${FunctionsRootUrl}/web/events/${eventId}/racer`
  return {
    url: racerId ? `${base}/${racerId}` : base,
    headers: {
      Authorization: `Bearer ${auth.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      firstName: payload.firstName,
      lastName: payload.lastName,
      carName: payload.carName,
      bracket: payload.bracket,
      label: payload.label
    })
  }
}

export const useAddRacer =
  (eventId: string) => 
    useMutation((auth) =>
      async (payload: UpdateRacerPayload) => {
        const { url, headers, body } = extract(eventId, auth, payload)
        return await fetch(url, { method: 'POST', headers, body })
      }
    )

export const useUpdateRacer =
  (eventId: string, racerId: string) =>
    useMutation((auth) =>
      async (payload: UpdateRacerPayload) => {
        const { url, headers, body } = extract(eventId, auth, payload, racerId)
        return await fetch(url, { method: 'PUT', headers, body })
      }
    )

export const useAddRacerImage =
  (eventId: string, racerId: string) =>
    useMutation((auth) =>
      async (file: File) => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/racer/${racerId}/media`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`, 
            'Content-Type': file.type
          },
          body: file
        })
      }
    )

export const useBulkRacerUpload =
  (eventId: string) =>
    useMutation((auth) =>
      async (file: File) => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/bulk/racer`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.token}`, 
            'Content-Type': file.type
          },
          body: file
        })
      }
    )
  
export const useDeleteRacer =
  (eventId: string, racerId: string) =>
    useMutation((auth) =>
      async () => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/racer/${racerId}`, {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${auth.token}` }
        })
      }
    )
