import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import './assets/fonts/dseg/index.css'
import { AdminPage } from './views/AdminPage'
import { BullpenPage } from './views/BullpenPage'
import { EventPage } from './views/EventPage'
import { HomePage } from './views/HomePage'
import { LiveStatsPage } from './views/LiveStatsPage'
import { LiveBoard } from './views/LiveBoard'
import { RacerPage } from './views/RacerPage'
import { ThemeProvider } from '@mui/material'
import { theme } from './Theme'
import { NewLiveBoard } from './views/NewLiveBoard'
import { FavoritesPage } from './views/FavoritesPage'


function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <HomePage /> }/>
          <Route path="/event/:eventId" element={ <EventPage /> }/>
          <Route path="/event/:eventId/live/stats" element={ <LiveStatsPage /> }/>
          <Route path="/event/:eventId/live/board" element={ <LiveBoard /> }/>
          <Route path="/event/:eventId/live/newBoard" element={ <NewLiveBoard /> }/>
          <Route path="/event/:eventId/racer/:racerId" element={ <RacerPage /> }/>
          <Route path="/event/:eventId/bullpen" element={ <BullpenPage /> }/>
          <Route path="/event/:eventId/admin" element={ <AdminPage /> }/>
          <Route path="/favorites" element={ <FavoritesPage /> }/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
