import { FunctionComponent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BracketSelector, FamilySelector } from '../components/Selectors'
import { EventCentricView } from '../components/Header'
import { OverallResults } from '../components/ResultsList'
import { DownloadCsv } from '../components/DownloadCsv'
import { Box } from '@mui/material'

export const EventPage: FunctionComponent<{}> =
  () => {
    const params = useParams()
    const eventId = params.eventId as string
    const [ brackets, setBrackets ] = useState([] as string[])
    const [ families, setFamilies ] = useState([] as string[])

    return (
      <EventCentricView>
        <Box display="flex">
          <BracketSelector eventId={eventId} selected={brackets} onSelect={setBrackets} />
          <FamilySelector eventId={eventId} selected={families} onSelect={setFamilies} />
          <Box flex="1" />
          <DownloadCsv />
        </Box>
        <OverallResults eventId={eventId} brackets={brackets} lastNames={families} />
      </EventCentricView>
    )
  }