import { SecondsBasedDuration, SecondsBasedTimeUnit } from '@derbytronics/models'
import { FunctionComponent } from 'react'

type Props = {
  value?: SecondsBasedDuration | null
  units?: SecondsBasedTimeUnit
  fixed?: number
  default?: string
}

const UnitScales = {
  'ns': Math.pow(10, -9),
  'µs': Math.pow(10, -6),
  'ms': Math.pow(10, -3),
  's': 1,
  'ks': Math.pow(10, 3),
  'Ms': Math.pow(10, 6),
  'Gs': Math.pow(10, 9)
}

export const Duration: FunctionComponent<Props> =
  ({ value, units, fixed, default: _default }) => {
    if (!value) return _default ? (<>{_default}</>) : null
    const t = convert(value, units || 's')
    const digits = fixed || 4
    const sigDigits = Math.max(1, Math.ceil(Math.log10(Math.abs(t))))
    const insigDigits = Math.max(0, digits - sigDigits)
    return (<>{ t.toFixed(insigDigits) }</>)
  }

export function convert(value: SecondsBasedDuration, units: SecondsBasedTimeUnit): number {
  const from = UnitScales[value.units]
  const to = UnitScales[units || 's']
  return value.value * from / to
}

export function minus(a: SecondsBasedDuration, b: SecondsBasedDuration, units?: SecondsBasedTimeUnit): number {
  const _units = units || 's'
  return convert(a, _units) - convert(b, _units)
}

export function equal(a: SecondsBasedDuration, b: SecondsBasedDuration): boolean {
  return minus(a, b, 'ms') === 0
}
