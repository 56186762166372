import { Box } from '@mui/material'
import { FunctionComponent, useEffect, useRef, useState } from 'react'

export const AutoScroller: FunctionComponent<{}> =
  ({ children }) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const childRef = useRef<HTMLDivElement>(null)
    const [ top, setTop ] = useState(0)
    
    useEffect(() => {
      const handle = setInterval(() => {
        const scrollDistance = parentRef.current?.offsetHeight || 0
        const maxTop = (childRef.current?.offsetHeight || 0) - scrollDistance
        const currentTop = -(childRef.current?.offsetTop || 0)

        if (currentTop >= maxTop) {
          setTop(0)
        } else {
          setTop(Math.min(currentTop + scrollDistance, maxTop))
        }
      }, 10000)

      return () => clearInterval(handle)
    }, [parentRef, childRef])

    return (
      <Box ref={parentRef} position="relative" overflow="hidden" height="100%">
        <Box ref={childRef} width="100%" sx={{ transition: 'top 500ms', position: 'absolute', top: -top, left: 0, right: 0 }}>
          {children}
        </Box>
      </Box>
    )
  }