import { AddAPhoto } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useAddRacerImage } from '../../services/RacerEditService'
import { useRacer } from '../../services/RacerService'
import { Form } from '../forms/Form'
import { FormPhoto } from '../forms/FormUpload'

type Props = {
  eventId: string
  racerId: string
  disabled: boolean
}

export const AddRacerPhoto: FunctionComponent<Props> =
  ({ eventId, racerId, disabled }) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useAddRacerImage(eventId, racerId)
    const racer = useRacer(eventId, racerId)

    return (
      <>
        <IconButton disabled={disabled} onClick={() => setOpen(true)}>
          <AddAPhoto />
        </IconButton>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title={`Add / Change Photo for ${racer?.carName}`}
          submit={d => submit(d.file)}
          submitText="Upload"
        >
          <FormPhoto id="file" label={`Add / Change Photo for Racer #${racerId}`} />
        </Form>
      </>
    )
  }
