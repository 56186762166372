import { Heat, SecondsBasedDuration } from '@derbytronics/models'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { Box, Button, Paper, Typography } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { Duration, equal } from '../components/Duration'
import { LivePlace } from '../components/LivePlace'
import { useCurrentEvent } from '../services/EventService'
import { useHeats } from '../services/HeatsService'
import { Original, mediaUrl } from '../services/MediaUrlService'
import { useRacer } from '../services/RacerService'
import { useStatistic } from '../services/StatisticsService'

export const NewLiveBoard: FunctionComponent<{}> =
  () => {
    const { event, eventId } = useCurrentEvent()
    const [ heatNum, setHeatNum ] = useState(-1)
    const heats = useHeats(eventId)
    const heat = heats?.[heatNum]

    function changeHeat(delta: number): void {
      const next = Math.min((heats?.length || 1) - 1, Math.max(0, heatNum + delta))
      setHeatNum(next)
    }

    // On load when no heat is yet selected, select the next heat up to start
    useEffect(() => {
      if (heatNum === -1 && heats) {
        const next = Math.max(heats.findIndex(h => !h.done), 0)
        setHeatNum(next)
      }
    }, [ heatNum, heats ])

    // Key listener to switch heats
    useEffect(() => {
      const handler = (event: KeyboardEvent) => {
        if (event.code === 'ArrowRight') {
          changeHeat(1)
        } else if (event.code === 'ArrowLeft') {
          changeHeat(-1)
        }
        event.preventDefault()
        event.stopPropagation()
      }
      document.addEventListener('keydown', handler)
      return () => document.removeEventListener('keydown', handler)

      // Can't include the changeHeat function as it would cause this to re-run every single time
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ heats, heatNum ])

    if (!event || !heat || !heats) { return null }

    const winning = extractWiningInfo(heat)

    return (
      <>
        <Box display="flex" flexDirection="row" mt={2} ml={2}>
          <Button size="small" onClick={() => changeHeat(-1)}><NavigateBefore /></Button>
          <Typography variant="h2">Heat {heat.order + 1} / { heats.length }</Typography>
          <Button size="small" onClick={() => changeHeat(1)}><NavigateNext /></Button>
        </Box>

        <Box display="flex" flexDirection="column" width="fit-content">
          { Array(event.lanes).fill(0).map((v, i) => <LiveRacerTab key={i} eventId={eventId} heat={heat} lane={i} />) }
        </Box>
        
        <LiveTimer start={heat.startTimestamp} final={winning.result} />
        <LiveRacerBackground eventId={eventId} racerId={winning.racerId} />
        <FastestStatsOverlay eventId={eventId} info={winning} />
      </>
    )
  }

type WinningInfo = {
  lane?: number
  racerId?: string
  result?: SecondsBasedDuration
}

function extractWiningInfo(heat: Heat): WinningInfo {
  const lane = heat.places?.indexOf(1)

  return (typeof lane !== 'number') ? {  } :
    {
      lane,
      racerId: heat.racers[lane] || undefined,
      result: heat.result?.[lane] || undefined
    }
}

type LiveRacerTabProps = {
  eventId: string
  heat: Heat
  lane: number
}

const LiveRacerTab: FunctionComponent<LiveRacerTabProps> =
  ({ eventId, heat, lane }) => {
    const racer = useRacer(eventId, heat.racers[lane] || '')
    const result = heat.result?.[lane]

    return (
      <Paper sx={{ mt: 3, ml: -2, pl: 2, display: 'flex' }}>
        <LivePlace p={ heat.places?.[lane] } />
        <Box p={2} flex="1">
          <Typography variant="h3">{ racer?.carName }</Typography>
          <Typography variant="h3" fontStyle="italic" color="#888">{ racer?.firstName } { racer?.lastName }</Typography>
        </Box>
        <RacerTime t={result} />
      </Paper>
    )
  }

const RacerTime: FunctionComponent<{ t: SecondsBasedDuration | undefined | null }> =
  ({t}) => {
    const defined = !['null', 'undefined'].includes(typeof t)
    const width = defined ? 150 : 0
    return (
      <Box width={width} sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
        background: 'black',
        color: 'white'
      }}>
        <Typography variant="h3" fontFamily="DSEG14-Modern">
          <Duration value={t} />
        </Typography>
      </Box>
    )
  }

type LiveTimerProps = {
  start?: number
  final?: SecondsBasedDuration | null
}

const LiveTimer: FunctionComponent<LiveTimerProps> =
  ({ start, final }) => {
    const [ dynamic, setDyamic ] = useState<SecondsBasedDuration | null>(null)

    useEffect(() => {
      if (start && !final) {
        const handle = setInterval(() => {
          setDyamic({ value: new Date().getTime() - start, units: 'ms' })
        }, 1)
        return () => clearInterval(handle)
      } else if (!start || final) {
        setDyamic(null)
      }
    }, [ start, final ])

    const d = final || dynamic

    return (
      <Paper sx={{
        position: 'fixed',
        right: '150px',
        top: '80px',
        width: '500px',
        height: '130px',
        backgroundColor: 'black',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Typography variant="h1" fontSize="80px" fontFamily="DSEG14-Modern">
          { d ? <Duration value={d}/> : '-.---' }
        </Typography>
      </Paper>
    )
  }

const LiveRacerBackground: FunctionComponent<{ eventId: string, racerId: string | undefined }> =
  ({ eventId, racerId }) => {
    const racer = useRacer(eventId, racerId || '')
    if (!racer) { return null }

    return (
      <Box 
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          top: 0,
          left: '10%',
          backgroundImage: `url(${mediaUrl(racer.media, Original)})`,
          backgroundPosition: 'right bottom',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          zIndex: -1000,
        }}
      />
    )
  }

type FastestStatsOverlayProps = {
  eventId: string
  info: WinningInfo
}

const FastestStatsOverlay: FunctionComponent<FastestStatsOverlayProps> =
  ({ eventId, info }) => {
    const fastest = useStatistic(eventId)
    const fastestLane = useStatistic(eventId, info.lane)

    const isFastest = fastest && info.result && equal(fastest.fastestTime, info.result)
    const isFastestLane = !isFastest && fastestLane && info.result && equal(fastestLane.fastestTime, info.result)

    if (isFastest || isFastestLane) {
      return (
        <Paper sx={{
          position: 'fixed',
          right: 80,
          bottom: 80,
          p: 2,
          background: 'linear-gradient(45deg, #e1ffde, #a2ff99, #59cf4f)'
        }}>
          <Typography fontSize='80px' sx={{ textShadow: '3px 3px 10px white' }} >{ isFastest ? 'Track Record!' : `Lane ${(info.lane || 0) + 1} Record!` }</Typography>
        </Paper>
      )
    } else {
      return null
    }
  }
