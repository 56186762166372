import { FunctionComponent } from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CheckeredFlagIcon: FunctionComponent<SvgIconProps> =
  (props) => (
    <SvgIcon {...props} viewBox="0 0 200 192">
      <path
          id="path4"
          connector-curvature="0"
          d="m162 0c-12.4 11.6-27.4 16.4-44 20l10.2 37.8c5.2-1 9.4-2.2 15.6-4.6l-11.2-30.6c11-3 19-7 26.8-12.6l11.4 28.4c-9.6 7.6-17.4 11.6-27 14.8l12.2 33.8c12.2-5.6 19.6-10.8 27-18l10.4 26.4c-5.6 7.6-16.8 15.6-26.8 20.8l-10.6-29.2c-4.6 2.4-12.2 5.4-18 7l-40-90c-34-2-62 8-82 26l48 122c18-24 60-50 74-50 10 0 4 12-8 24.2 18-27.2-10-14.2-20-3.2 12 27 78-5 90-27l-38-96zm-68 10 12 26c-13 1-26 4-36 8l16 37c9.8-4.2 23.8-9 35-10.4l11.6 26c-10.4 2.4-24.8 10.6-33.2 16.4l-13.4-32c-12 6-24 12.8-35 22l-15-39c10-8 20-14 34-20l-12-30c12-4 24-4 36-4zm-85 18-9 4 56 160 15-6-62-158z"
      />
    </SvgIcon>
  )