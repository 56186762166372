import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, SxProps, Typography } from '@mui/material'
import { FunctionComponent, PropsWithChildren, createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from '../../services/FirebaseAuthService'
import { Login } from '../Login'
import { MutationButton } from '../MutationButton'

export type DataState = { [key: string]: { value: any, invalid?: boolean } }

export function toDataState(data: { [key: string]: any } | undefined): DataState {
  if (!data) { return {} }
  const ds: DataState = {}
  Object.keys(data).forEach(k => { ds[k] = { value: data[k] } })
  return ds
}

export type FormContextData = {
  data: DataState
  setValue: (key: string, value: any, invalid?: boolean) => void
}

export const FormContext = createContext<FormContextData>({data: {}, setValue: () => {}})

export type ErrorOutput = { message: string, severity: 'error' | 'warning' }
export type ErrorMapper = (responseCode: number, message: string | undefined) => ErrorOutput
export type FormProps = {
  open: boolean
  onClose?: () => void
  onSuccess?: () => void
  redirectOnSuccess?: (data: { [key: string]: any }, json: { [key: string]: any }) => string | undefined
  keepDataOnSuccess?: boolean
  loading: boolean
  requireAuth?: boolean
  authMessage?: string
  title: string
  initialData?: DataState
  submit: (data: { [key: string]: any }) => Promise<Response>
  errorMapper?: ErrorMapper
  errorTimeout?: number
  isOkResponse?: (code: number) => boolean
  submitText?: string
  variant?: 'dialog' | 'paper'
  sx?: SxProps

  /* If set, this flips the colors in the form to indicate the "submit" action is red  */
  destructive?: boolean
}

const DefaultErrorMapper: ErrorMapper =
  (responseCode: number) => ({ severity: 'error', message: `Failed to submit request (code: ${responseCode})` })

const DefaultIsOkResponse = (code: number) => (code >= 200 && code < 300)

export const Form: FunctionComponent<PropsWithChildren<FormProps>> =
  (props) => {
    const auth = useAuthState()
    const navigate = useNavigate()
    const [ error, setError ] = useState<ErrorOutput | undefined>(undefined)
    const [ data, setData ] = useState<DataState>(props.initialData || {})
    const ctx: FormContextData = {
      data,
      setValue: (key: string, value: any, invalid?: boolean) => {
        setData({ ...data, [key]: { value, invalid }})
      }
    }

    const disabled = !!Object.values(data).find(({ invalid }) => !!invalid )

    function onError(responseCode: number, message: string | undefined) {
      const out = (props.errorMapper || DefaultErrorMapper)(responseCode, message)
      setError(out)
      if (props.errorTimeout && props.errorTimeout > 0) {
        setTimeout(() => setError(undefined), props.errorTimeout)
      }
    }

    async function save() {
      if (disabled || props.loading) { return }
      setError(undefined)
      try {
        const payload: Record<string, any> = {}
        Object.keys(data).forEach(k => { payload[k] = data[k].value })
        const response = await props.submit(payload)
        const code = response.status
        if ((props.isOkResponse || DefaultIsOkResponse)(code)) {
          props.onClose?.()
          props.onSuccess?.()
          const json = await response.json() as { [key: string]: any }
          const redirect = props.redirectOnSuccess?.(payload, json)
          redirect && navigate(redirect)
          if (!props.keepDataOnSuccess) {
            setData(props.initialData || {})
          }
        } else {
          const msg = response.body?.toString()
          onError(code, msg)
        }
      } catch (e) {
        onError(-1, '' + e)
      }
    }
    
    if (!auth && props.requireAuth) {
      return (
        <Dialog open={props.open} onClose={props.onClose}>
          <Login>{ props.authMessage || 'Please login to make edits' }</Login>
        </Dialog>
      )
    } else if (props.variant === 'paper') {
      return (
        <FormContext.Provider value={ctx}>
          <Paper component="form" sx={props.sx} >
            <Typography variant="h4" sx={{ mb: 2 }}>{props.title}</Typography>
            { props.children }
            { error && <Alert severity={error.severity}>{error.message}</Alert> }
            
            <Box textAlign="right" sx={{ mt: 1 }}>
              <MutationButton color={props.destructive ? 'error' : 'success'} loading={props.loading} submit={save} disabled={disabled}>{ props.submitText || 'Submit' }</MutationButton>
            </Box>
          </Paper>
        </FormContext.Provider>
      )
    } else {
      return (
        <FormContext.Provider value={ctx}>
          <Dialog open={props.open} onClose={props.onClose} component="form">
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
              { props.children }
              { error && <Alert severity={error.severity}>{error.message}</Alert> }
            </DialogContent>
            <DialogActions sx={{ p: 3, pt: 0 }}>
              <Button variant="contained" color={props.destructive ? 'inherit' : 'error'} onClick={() => props.onClose?.()}>Cancel</Button>
              <Box flex="1"/>
              <MutationButton
                color={props.destructive ? 'error' : 'success'}
                loading={props.loading}
                submit={save}
                disabled={disabled}
              >
                { props.submitText || 'Submit' }
              </MutationButton>
            </DialogActions>
          </Dialog>
        </FormContext.Provider>
      )
    }
  }
