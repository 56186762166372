import { Fab, styled, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import ArrowOfLightBadge from '../assets/badges/ArrowOfLightBadge.jpeg'
import BearBadge from '../assets/badges/BearBadge.jpeg'
import LionBadge from '../assets/badges/LionBadge.jpeg'
import TigerBadge from '../assets/badges/TigerBadge.jpeg'
import WeblosBadge from '../assets/badges/WebelosBadge.png'
import WolfBadge from '../assets/badges/WolfBadge.jpeg'

type Props = {
  rank: string
}

export const sx = {
  maxWidth: 50,
  maxHeight: 50
}

const IconImage = styled('img')(({theme}) => (sx))

export const RankBadgeIcon: FunctionComponent<Props> =
  ({rank}) => {
    switch (rank) {
      case 'Arrow of Light': return <IconImage src={ArrowOfLightBadge} />
      case 'Bear': return <IconImage src={BearBadge} />
      case 'Lion': return <IconImage src={LionBadge} />
      case 'Tiger': return <IconImage src={TigerBadge} />
      case 'Webelos': return <IconImage src={WeblosBadge} />
      case 'Wolf': return <IconImage src={WolfBadge} />
      case 'Sibling': return <Fab color="warning" sx={sx}><Typography>SI</Typography></Fab>
      default: return <Fab color="secondary" sx={sx}><Typography>??</Typography></Fab>
    }
  }