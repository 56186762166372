// Import the functions you need from the SDKs you need
import * as firebase from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4TIaeipYOV1Pxg9GBa7sOjOrTgT4NqeY",
  authDomain: "derbytronics.firebaseapp.com",
  projectId: "derbytronics",
  storageBucket: "derbytronics.appspot.com",
  messagingSenderId: "967542841476",
  appId: "1:967542841476:web:5e6e8b811153cbe590e2d1",
  measurementId: "G-RK656WQ923"
}

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)
export const functions = getFunctions(app)

// Check to see if the emulators should be attached
export const useEmulators = process.env.NODE_ENV === 'development'
if (useEmulators) {
  console.log(`Using Firebase Emulators`)
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export const FunctionsRootUrl =
  useEmulators ? `http://localhost:5001/${firebaseConfig.projectId}/us-central1`
               : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`