import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useCurrentEvent } from '../services/EventService'
import { Download } from '@mui/icons-material'
import { FunctionsRootUrl } from '../services/FirebaseConfig'

type Props = {}

export const DownloadCsv: FunctionComponent<Props> =
  ({  }) => {
    const { eventId, event } = useCurrentEvent()
    const [ open, setOpen ] = useState(false)

    return (
      <>
        <Fab
          onClick={() => setOpen(true)}
          variant="extended"
          size="medium"
          color="secondary"
        >
          <Download /> Download
        </Fab>

        <Dialog open={open} onClose={() => setOpen(false)} >
          <DialogTitle>Download Results</DialogTitle>
          <DialogContent>Download CSV for { event?.name }</DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={() => setOpen(false)}>Cancel</Button>
            <Box flex="1" minWidth={20}/>
            <Button
              href={`${FunctionsRootUrl}/web/events/${eventId}/results.csv`}
              download={`${event?.name}.results.csv`}
              variant="contained"
              color="success"
            >
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
