import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material'
import { Alert, Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useEvent } from '../../services/EventService'
import { LastNameRacerSorter, useRacerList } from '../../services/RacerService'
import { RankBadgeIcon } from '../RankBadgeIcon'
import { AddRacerPhoto } from './AddRacerPhoto'
import { BulkUploadRacerForm } from './BulkUploadRacerForm'
import { DeleteRacer } from './DeleteRacer'
import { AddNewRacer, UpdateRacer } from './RacerEntryForm'

export const AdminRacerList: FunctionComponent<{eventId: string}> =
  ({ eventId }) => {
    const racers = useRacerList(eventId, LastNameRacerSorter)
    const event = useEvent(eventId)
    const locked = event ? event.started : true

    return (
      <Box sx={{ p: 2 }}>
        { locked && <Alert severity="warning">The race has already started, you can no longer modify racers</Alert> }
        <Box sx={{ position: 'fixed', right: 32, bottom: 32 }}>
          <AddNewRacer disabled={locked} />
          <BulkUploadRacerForm disabled={locked} />
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="body1" fontWeight="bold">Bracket</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">First Name</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">Last Name</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">Car Name</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">Label</Typography></TableCell>
              <TableCell><Typography variant="body1" fontWeight="bold">Photo</Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { racers?.map(r => (
            <TableRow key={r.id}>
              <TableCell><RankBadgeIcon rank={r.bracket} /></TableCell>
              <TableCell>{r.firstName}</TableCell>
              <TableCell>{r.lastName}</TableCell>
              <TableCell>{r.carName}</TableCell>
              <TableCell>{ r.label }</TableCell>
              <TableCell>
                { r.media && <CheckBoxOutlined /> }
                { !r.media && <CheckBoxOutlineBlank /> }
              </TableCell>
              <TableCell>
                <UpdateRacer eventId={eventId} disabled={locked} racer={r} />
                <AddRacerPhoto eventId={eventId} disabled={locked} racerId={r.id} />
                <DeleteRacer eventId={eventId} disabled={locked} racer={r} />
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </Box>
    )
  }