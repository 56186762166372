import { EscapedHeat, Heat, SecondsBasedDuration } from '@derbytronics/models'
import { FunctionsRootUrl } from './FirebaseConfig'
import { SharedCollectionListenerService, useSharedHook } from './SharedListenerService'
import { useMutation } from './UseMutation'


/**
 * Note that this function is duplicated in `admin/src/EscapedHeat.ts`
 * @param heat the escaped heat
 * @returns the unescaped heat
 */
function unescapeHeat<H extends EscapedHeat | undefined>(heat: H): H extends undefined ? undefined : Heat {
  if (typeof heat === 'undefined') { return undefined as any }
  return {
    order: heat.order,
    racers: heat.racers.map(r => r === '' ? null : r),
    result: heat.result?.map(r => r === '' ? null : r as SecondsBasedDuration),
    places: heat.places?.map(p => p === '' ? null : p as number),
    ready: heat.ready,
    done: heat.done,
    resetCount: heat.resetCount,
    media: heat.media,
    startTimestamp: heat.startTimestamp,
  } as any
}

export type HeatCollection = { [id: string]: Heat }

class HeatService extends SharedCollectionListenerService<EscapedHeat> {

  private static singletons: { [eventId: string]: HeatService } = {}

  static for(eventId: string): HeatService {
    if (!(eventId in this.singletons)) {
      this.singletons[eventId] = new HeatService(eventId)
    }
    return this.singletons[eventId]
  }

  private constructor(readonly eventId: string) {
    super(`events/${eventId}/heats`)
  }
  
}

export function useHeat(eventId: string, heatId: string): Heat | undefined {
  return useSharedHook(
    HeatService.for(eventId),
    (data) => unescapeHeat(data[heatId]),
    [ eventId, heatId ]
  )
}

export function useHeatByOrder(eventId: string, heatOrder: number): Heat | undefined {
  return useSharedHook(
    HeatService.for(eventId),
    (data) => unescapeHeat(Object.values(data).find(h => h.order ===  heatOrder)),
    [ eventId, heatOrder ]
  )
}

export function useHeats(eventId: string): (Heat & { id: string })[] | undefined {
  return useSharedHook(
    HeatService.for(eventId),
    (data) => Object.keys(data).sort().map(id => ({ ...unescapeHeat(data[id]), id })),
    [ eventId ]
  )
}

export function useLastHeat(eventId: string): Heat | undefined {
  return useSharedHook(
    HeatService.for(eventId),
    (data) => unescapeHeat(Object.values(data).sort((a, b) => b.order - a.order).find(h => h.done)),
    [ eventId ]
  )
}

export function useNextHeat(eventId: string, count: number = 1): Heat[] {
  return useSharedHook(
    HeatService.for(eventId),
    (data) => {
      const sorted = Object.values(data).sort((a, b) => a.order - b.order)
      const firstIncompleteHeatIndex = sorted.findIndex(h => !h.done)
      return sorted.slice(firstIncompleteHeatIndex, firstIncompleteHeatIndex + count).map(unescapeHeat)
    },
    [ eventId ]
  ) || []
}

export const useDeleteHeat =
  (eventId: string, order: number) =>
    useMutation((auth) =>
      async () => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/result/heat/${order}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ })
        })
      }
    )
