import { Typography, TypographyProps, useMediaQuery, useTheme } from '@mui/material'
import { FunctionComponent} from 'react'


type Props = {
  variant?: TypographyProps['variant']
}

export const StyledName: FunctionComponent<Props> =
  ({ variant }) => {
    const theme = useTheme()
    const isTiny = useMediaQuery(theme.breakpoints.down('sm'))
    return (
      <Typography
        variant={ variant || 'h4' }
        fontFamily='DSEG14-Modern'
        component="div"
        sx={{ ml: 2, flexGrow: 1 }}
      >{isTiny ? 'dt' : 'derbytronics'}</Typography>
    )
  }