import { List, ListItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { EventCentricView } from '../components/Header'
import { Place } from '../components/Place'
import { useEventId } from '../services/EventService'
import { useRacerResults } from '../services/RacerResultsService'
import { useRacer, useRacerId, useRacerSchedule } from '../services/RacerService'
import { Duration } from '../components/Duration'

export const RacerPage: FunctionComponent<{}> =
  () => {
    const eventId = useEventId()
    const racerId = useRacerId()
    const racer = useRacer(eventId, racerId)
    const schedule = useRacerSchedule(eventId, racerId)
    const results = useRacerResults(eventId, racerId)

    return (
      <EventCentricView>
        <Typography variant="h1">{racer?.carName}</Typography>
        <Typography variant="h2">{racer?.firstName} {racer?.lastName}</Typography>
        <List>
          <ListItem><Typography>Average Time: <code><Duration value={racer?.averageTime} /></code></Typography></ListItem>
          <ListItem><Typography>Overall Place: {racer?.overallPlace ? <Place size="small" value={racer?.overallPlace} /> : 'TBD'}</Typography></ListItem>
          <ListItem><Typography>Place for {racer?.bracket}: {racer?.bracketPlace ? <Place size="small" value={racer?.bracketPlace} /> : 'TBD'}</Typography></ListItem>
        </List>

        <Table size="small" >
          <TableHead>
            <TableRow>
              <TableCell variant="head"><Typography fontWeight="bold">Heat</Typography></TableCell>
              <TableCell variant="head"><Typography fontWeight="bold">Lane</Typography></TableCell>
              <TableCell variant="head"><Typography fontWeight="bold">Time</Typography></TableCell>
              <TableCell variant="head"><Typography fontWeight="bold">Result</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { schedule.map(s => {
            const result = results?.find(r => r.heatOrder === s.heatOrder)
            return (
              <TableRow key={s.heatId}>
                <TableCell><Typography>{s.heatOrder + 1}</Typography></TableCell>
                <TableCell><Typography>{s.lane + 1}</Typography></TableCell>
                <TableCell><code><Duration value={result?.time} /></code></TableCell>
                <TableCell><Typography>{ result ? <Place size="small" value={result.place} /> : 'TBD' }</Typography></TableCell>
              </TableRow>
            )
          })}
          </TableBody>
        </Table>
      </EventCentricView>
      
    )
  }