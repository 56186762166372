import { Button, Typography } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useCreateEvent } from '../services/EventService'
import { Form } from './forms/Form'
import { DateFormField, NumberFormField, TextFormField } from './forms/FormField'

export const CreateEventButton: FunctionComponent<{}> =
  () => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useCreateEvent()

    return (
      <>
        <Button color="secondary" variant="contained" onClick={() => setOpen(true)} sx={{ mt: '-4px' }}>
          <Typography variant="h5" sx={{ textTransform: 'none' }}>Get Started</Typography>
        </Button>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          submit={ (d) => submit(d.name, d.date, d.lanes) }
          initialData={{
            name: { value: '', invalid: true },
            date: { value: new Date().toISOString().substring(0,10) },
            lanes: { value: 6 }
          }}
          redirectOnSuccess={(_, { eventId }) => `/event/${eventId}/admin`}
          title="Enter Event Details"
        >
          <TextFormField id="name" label="Name" required />
          <DateFormField id="date" label="Date" required />
          <NumberFormField id="lanes" label="Lanes" required />
        </Form>
      </>
    )
  }
