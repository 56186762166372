import { UploadFile } from '@mui/icons-material'
import { DialogContentText, Fab } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useEventId } from '../../services/EventService'
import { useBulkRacerUpload } from '../../services/RacerEditService'
import { Form } from '../forms/Form'
import { FormCsv } from '../forms/FormUpload'

type Props = {
  disabled: boolean
}

export const BulkUploadRacerForm: FunctionComponent<Props> =
  ({ disabled }) => {
    const [ open, setOpen] = useState(false)
    const eventId = useEventId()
    const { loading, submit } = useBulkRacerUpload(eventId)

    return (
      <>

        <Fab
          onClick={() => setOpen(true)}
          variant="extended"
          color="success"
          disabled={disabled}
          sx={{ ml: 2 }}
        >
          <UploadFile />
        </Fab>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title="Bulk upload racers"
          submit={d => submit(d.file)}
          submitText="Upload"
        >
          <DialogContentText>
            Upload a CSV file including headers in the following format:
            <pre>
              firstName,lastName,carName,bracket,label
            </pre>
            The last column (label) is optional
          </DialogContentText>
          <FormCsv id="file" label="Upload CSV File" />
        </Form>
      </>
    )
  }