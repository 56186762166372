import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material'
import { Fab, IconButton, Tooltip } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useEventId } from '../../services/EventService'
import { UpdateRacerPayload, useAddRacer, useUpdateRacer } from '../../services/RacerEditService'
import { RacerWithId } from '../../services/RacerService'
import { Form, toDataState } from '../forms/Form'
import { TextFormField } from '../forms/FormField'
import { FormSelect } from '../forms/FormSelect'

type Props = {
  open: boolean,
  setOpen: (open: boolean) => void,
  initial?: UpdateRacerPayload
  loading: boolean
  submit: (payload: UpdateRacerPayload) => Promise<Response>
}

const brackets = [ 'Lion', 'Tiger', 'Wolf', 'Bear', 'Webelos', 'Arrow of Light', 'Sibling' ]

const RacerEntryForm: FunctionComponent<Props> =
  ({ open, setOpen, initial, loading, submit }) => {
    return (
      <Form
        open={open}
        onClose={() => setOpen(false)}
        loading={loading}
        initialData={toDataState(initial)}
        submit={(d) => submit(d as UpdateRacerPayload)}
        title="Enter Racer Info"
      >
        <TextFormField id="firstName" label="First Name" required />
        <TextFormField id="lastName" label="Last Name" required />
        <TextFormField id="carName" label="Car Name" required />
        <TextFormField id="label" label="Label" />
        <FormSelect id="bracket" label="Bracket (Den)" required options={brackets} />
      </Form>
    )
  }

type AddNewRacerProps = {
  disabled?: boolean
}

export const AddNewRacer: FunctionComponent<AddNewRacerProps> =
  ({ disabled }) => {
    const eventId = useEventId()
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useAddRacer(eventId)
    
    return (
      <>
        <Fab
          onClick={() => setOpen(true)}
          variant="extended"
          color="success"
          disabled={disabled}
        >
          <AddIcon sx={{ mr: 1 }}/>New Racer
        </Fab>
        <RacerEntryForm
          loading={loading}
          submit={submit}
          open={open}
          setOpen={setOpen}
        />
      </>
    )
  }

type UpdateRacerProps = {
  eventId: string
  disabled?: boolean
  racer: RacerWithId
}

export const UpdateRacer: FunctionComponent<UpdateRacerProps> =
  ({ disabled, racer }) => {
    const eventId = useEventId()
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useUpdateRacer(eventId, racer.id)
    
    return (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => setOpen(true)}
            disabled={disabled}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <RacerEntryForm
          loading={loading}
          submit={submit}
          open={open}
          setOpen={setOpen}
          initial={{
            firstName: racer.firstName,
            lastName: racer.lastName,
            carName: racer.carName,
            bracket: racer.bracket,
            label: racer.label
          }}
        />
      </>
    )
  }
