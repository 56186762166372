import { Box, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import QRCode from 'react-qr-code'
import { useCurrentEvent } from '../services/EventService'

export const EventDetails: FunctionComponent<{}> =
  () => {
    const { eventId, event } = useCurrentEvent()

    return (
      <Box display="flex" flexDirection="column" maxHeight="100%">
        <Typography variant="h2" align="center">{event?.name}</Typography>
        <QRCode
          style={{ height: "auto", maxWidth: "100%", maxHeight: "100%", width: "100%" }}
          viewBox="0 0 256 256"
          value={`https://derbytronics.com/event/${eventId}`}
        />
        <Typography align="center">Scan Here for Results</Typography>
      </Box>
    )
  }