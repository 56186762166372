import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useHeats } from '../../services/HeatsService'
import { useRacerCollection } from '../../services/RacerService'

export const HeatAssignmentView: FunctionComponent<{ eventId: string, lanes: number }> =
  ({ eventId, lanes }) => {
    const heats = useHeats(eventId)
    const racers = useRacerCollection(eventId)

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell><Typography fontWeight="bold">Heat #</Typography></TableCell>
            {
              [...Object.keys(Array(lanes).fill(0))].map(i => (
                <TableCell key={i}><Typography fontWeight="bold">Lane {+i + 1}</Typography></TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          { heats?.map(h => (
            <TableRow key={h.id}>
              <TableCell>{h.order}</TableCell>
              { h.racers.map((r, i) => {
                const racer = r ? racers?.[r] : null
                return (<TableCell key={i}>{racer?.label || r}</TableCell>)
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }