import { Add as AddIcon, DeleteForever } from '@mui/icons-material'
import { DialogContentText, Fab, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useAuth } from '../../services/FirebaseAuthService'
import { useCreateUser, useDeleteUser, useUserList } from '../../services/UsersService'
import { Form } from '../forms/Form'
import { TextFormField } from '../forms/FormField'

export const UsersList: FunctionComponent<{ eventId: string }> =
  ({ eventId }) => {
    const users = useUserList(eventId)
    const auth = useAuth()
    
    return (
      <>
        <UserEntryForm eventId={eventId} />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="body1" fontWeight="bold">Email</Typography></TableCell>
              <TableCell></TableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
          { users.map(u => (
            <TableRow key={u.email}>
              <TableCell><Typography variant="body1" fontFamily="monospace">{u.email}</Typography></TableCell>
              <TableCell><DeleteUser eventId={eventId} email={u.email} disabled={u.email === auth?.email}/></TableCell>
            </TableRow>
          )) }
          </TableBody>
        </Table>
      </>
    )
  }

type DeleteUserProps = {
  eventId: string
  email: string
  disabled?: boolean
}

export const DeleteUser: FunctionComponent<DeleteUserProps> =
  ({eventId, email, disabled}) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useDeleteUser(eventId, email)

    return (
      <>
        <IconButton disabled={disabled} color="error" onClick={() => setOpen(true)}>
          <DeleteForever />
        </IconButton>

        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title={`Delete ${email}?`}
          submit={submit}
          submitText="Delete"
          destructive
        >
          <DialogContentText>
            Are you sure you want to delete {email}?
            This is a permenant action and cannot be undone.
          </DialogContentText>
        </Form>
      </>
    )
  }

export const UserEntryForm: FunctionComponent<{eventId: string}> =
  ({ eventId }) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useCreateUser(eventId)

    return (
      <>
        <Fab
          onClick={() => setOpen(true)}
          variant="extended"
          color="success"
          sx={{ position: 'fixed', right: 32, bottom: 32 }}
        >
          <AddIcon sx={{ mr: 1 }}/>New Admin User
        </Fab>

        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title="Add New Admin User"
          submit={(d) => submit(d.email)}
        >
          <DialogContentText>
            Enter the user's email address below. This will grant them admin access
            as well as the track authority which means that the user can submit times
            as if it came from the track itself.
          </DialogContentText>
          <TextFormField id="email" label="Email" required />
        </Form>
      </>
    )
  }
