import { useEmulators } from './FirebaseConfig'

export class MediaPathModifier {
  constructor(
    public readonly suffix: string,
    public readonly format?: string
  ) {}

  transform(path: string): string {
    const lastPeriod = path.lastIndexOf('.')
    const pathMinusFormat = path.substring(0, lastPeriod)
    const sourceFormat = path.substring(lastPeriod + 1)
    return `${pathMinusFormat}${this.suffix}.${this.format || sourceFormat}`
  }
}

export class MediaSize extends MediaPathModifier {
  constructor(width: number, height: number, format?: string) {
    super(`_${width}x${height}`, format)
  }
}

export const Original = new MediaPathModifier('')
export const Size50x50 = new MediaSize(50, 50, 'webp')
export const Size500x500 = new MediaSize(500, 500, 'webp')
export const Size750x750 = new MediaSize(750, 7500, 'webp')
export const Size1000x1000 = new MediaSize(1000, 1000, 'webp')

export function mediaUrl(path: string | undefined, size: MediaPathModifier = Original): string {
  if (!path) { return ''}

  const [ prefix, suffix ] = useEmulators ?
    [ 'http://localhost:9199/derbytronics.appspot.com/', '' ] :
    [ 'https://firebasestorage.googleapis.com/v0/b/derbytronics.appspot.com/o/', '?alt=media' ]

  return `${prefix}${encodeURIComponent(size.transform(path))}${suffix}`
}
