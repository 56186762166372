import { DeleteForever } from '@mui/icons-material'
import { Box, DialogContentText, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useDeleteHeat, useHeats } from '../../services/HeatsService'
import { RacerNames } from '../RacerNames'
import { Form } from '../forms/Form'

export const ResetHeats: FunctionComponent<{ eventId: string }> =
  ({ eventId }) => {
    const heats = useHeats(eventId)

    return (
      <Box display="inline-block">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><Typography fontWeight="bold">Heat</Typography></TableCell>
              <TableCell><Typography fontWeight="bold">Places</Typography></TableCell>
              <TableCell><Typography fontWeight="bold">Results</Typography></TableCell>
              <TableCell><Typography fontWeight="bold">Lane 1 Racer</Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          { heats?.filter(h => h.done)?.map(h => (
            <TableRow key={h.id}>
              <TableCell>#{h.order + 1}</TableCell>
              <TableCell>{h.places?.length}</TableCell>
              <TableCell>{h.result?.length}</TableCell>
              <TableCell>{ h.racers[0] && <RacerNames eventId={eventId} racerId={h.racers[0]} /> }</TableCell>
              <TableCell><DeleteHeat eventId={eventId} order={h.order} /></TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </Box>
    )
  }


type DeleteHeatProps = {
  eventId: string
  order: number
}

export const DeleteHeat: FunctionComponent<DeleteHeatProps> =
  ({eventId, order}) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useDeleteHeat(eventId, order)

    return (
      <>
        <IconButton color="error" onClick={() => setOpen(true)}>
          <DeleteForever />
        </IconButton>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title={`Delete Heat #${order + 1}?`}
          submit={submit}
          submitText="Delete"
          destructive
        >
          <DialogContentText>
            Are you sure you want to delete this heat? You will lose
            all records of the results associated with this heat.
            This is a permenant action and cannot be undone.
          </DialogContentText>
        </Form>
      </>
    )
  }
