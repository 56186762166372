import { Tab, Tabs } from '@mui/material'
import { Fragment, FunctionComponent, useState } from 'react'

export type SimpleTabDef = {
  name: string
  component: () => JSX.Element
}

export type SimpleTabsProps = {
  tabs: SimpleTabDef[]
}

export const SimpleTabs: FunctionComponent<SimpleTabsProps> =
  ({ tabs }) => {
    const [ selected, setSelected ] = useState(0)

    return (
      <Fragment>
        <Tabs
          value={selected}
          onChange={(_ ,value) => setSelected(value)}
          variant="scrollable"
          scrollButtons="auto"
        >
          { tabs.map(t => <Tab label={t.name} key={t.name} />) }
        </Tabs>
        { tabs[selected].component() }
      </Fragment>
    )
  }