import { Box, Paper, Typography } from '@mui/material'
import { FunctionComponent, useEffect, useState } from 'react'
import { useCurrentEvent } from '../services/EventService'
import { useStatistic } from '../services/StatisticsService'
import { RacerNames } from './RacerNames'
import { Duration } from './Duration'

type Props = {}

export const StatsCarousel: FunctionComponent<Props> =
  () => {
    const { eventId, event } = useCurrentEvent()
    const [ pointer, setPointer ] = useState(0)

    useEffect(() => {
      let _pointer = 0
      const handle = setInterval(() => {
        setPointer(++_pointer)
      }, 8000)

      return () => clearInterval(handle)
    }, [ event ])

    if (!eventId || !event) return null

    const lanes =
      [ undefined, ...Object.keys([...new Array(event.lanes)]).map(l => +l) ]
        .map((l, i) => {
          const me = (off: number) => ((pointer + off) % (event.lanes + 1)) === i
          return { lane: l, last: me(-1), current: me(0)  }
        })

    return (
      <Box sx={{ position: 'relative', height: '110px' }} >
        { lanes.map(l => <SimpleStat key={'' + l.lane} eventId={eventId} {...l} />) }
      </Box>
    )
  }

type SimpleStatProps = {
  eventId: string
  lane?: number
  last: boolean
  current: boolean
}

const SimpleStat: FunctionComponent<SimpleStatProps> = 
  ({ eventId, lane, last, current }) => {
    const [ shown, setShown ] = useState(last)
    const [ init, setInit ] = useState(true)
    const stat = useStatistic(eventId, lane)

    useEffect(() => {
      setInit(true)
      setShown(last)

      setTimeout(() => {
        setShown(!last)
        setInit(false)
      }, 1000)
    }, [ last, current ])

    return (stat && (
      <Paper
        sx={{
          position: 'absolute',
          bottom: 0, left: 0, right: 0,
          display: (current || last) ? 'grid' : 'none',
          gridTemplateRows: '100%',
          gridTemplateColumns: 'repeat(3, 1fr)',
          alignItems: 'center',
          p: 2,
          opacity: shown ? 1 : 0,
          transition: init ? 'none' : 'opacity 2s',
        }}
      >
        <Typography variant="h4">Fastest {lane === undefined ? 'Overall' : `Lane ${lane + 1}`}</Typography>
        <Box>
          <pre style={{ fontSize: 28, margin: 0 }}><Duration value={stat.fastestTime} /></pre>
          <pre style={{ fontSize: 14, margin: 0 }}>avg: <Duration value={stat.averageTime} /></pre>
        </Box>
        <Box><RacerNames eventId={eventId} racerId={stat.fastestRacerId}/></Box>
      </Paper>
    )) || null
  }
