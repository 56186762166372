import { Add as AddIcon, DeleteForever } from '@mui/icons-material'
import { DialogContentText, Fab, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useApiUserList, useCreateApiUser, useDeleteApiUser } from '../../services/ApiUsersService'
import { Form } from '../forms/Form'
import { TextFormField } from '../forms/FormField'

export const ApiUsersList: FunctionComponent<{ eventId: string }> =
  ({ eventId }) => {
    const users = useApiUserList(eventId)
    
    return (
      <>
        <ApiUserEntryForm eventId={eventId} />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="body1" fontWeight="bold">Key</Typography></TableCell>
              <TableCell></TableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
          { users.map(u => (
            <TableRow key={u.key}>
              <TableCell><Typography variant="body1" fontFamily="monospace">{u.key}</Typography></TableCell>
              <TableCell><DeleteApiUser eventId={eventId} apiKey={u.key} /></TableCell>
            </TableRow>
          )) }
          </TableBody>
        </Table>
      </>
    )
  }

type DeleteApiUserProps = {
  eventId: string
  apiKey: string
}

export const DeleteApiUser: FunctionComponent<DeleteApiUserProps> =
  ({eventId, apiKey}) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useDeleteApiUser(eventId, apiKey)

    return (
      <>
        <IconButton color="error" onClick={() => setOpen(true)}>
          <DeleteForever />
        </IconButton>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title={`Delete ${apiKey}?`}
          submit={submit}
          submitText="Delete"
          destructive
        >
          <DialogContentText>
            Are you sure you want to delete {apiKey}?
            This is a permenant action and cannot be undone.
          </DialogContentText>
        </Form>
      </>
    )
  }

export const ApiUserEntryForm: FunctionComponent<{eventId: string}> =
  ({ eventId }) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useCreateApiUser(eventId)

    return (
      <>
        <Fab
          onClick={() => setOpen(true)}
          variant="extended"
          color="success"
          sx={{ position: 'fixed', right: 32, bottom: 32 }}
        >
          <AddIcon sx={{ mr: 1 }}/>New API Key
        </Fab>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title="Add New API Key"
          submit={(data) => submit(data.key)}
        >
          <DialogContentText>
            Enter a sufficiently complicated and random string to use as an API key. This should
            be kept secret since it will allow admin access to your event.
          </DialogContentText>
          <TextFormField id="key" label="API Key" required />
        </Form>
      </>
    )
  }
