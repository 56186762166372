import { Box } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { AutoScroller } from '../components/AutoScroller'
import { EventDetails } from '../components/EventDetails'
import { HeatProgressBar } from '../components/HeatProgressBar'
import { HeatQuickView } from '../components/HeatQuickView'
import { BracketedResultsList, ResultsList } from '../components/ResultsList'
import { StatsCarousel } from '../components/StatsCarousel'
import { useEventId } from '../services/EventService'

export const LiveStatsPage: FunctionComponent<{}> =
  () => {
    const eventId = useEventId()
    const [ showOverall ] = useState(false)

    return (
      <Box sx={{ m:0, p:0, height: '100vh', display: 'grid', gridTemplateRows: 'auto 15vh 80px', gridTemplateColumns: '67% 33%'}} >

        <Box sx={{ gridRow: 1, gridColumn: 1, p: 2, overflow: 'hidden' }}>
          <HeatQuickView eventId={eventId} upcomingCount={1} />
        </Box>

        <Box sx={{ gridRow: "1/3", gridColumn: 2, p: 2, overflow: 'hidden' }}>
          <AutoScroller>
            <EventDetails />
            { showOverall ? 
                (<ResultsList eventId={eventId} hideHeader compact hideBracketPlace />) :
                (<BracketedResultsList eventId={eventId} />)
            }
          </AutoScroller>
        </Box>

        <Box sx={{gridRow: 2, gridColumn: 1, p: 2, alignSelf: 'end', overflow: 'hidden' }}>
          <StatsCarousel />
        </Box>

        <Box sx={{  gridRow: 3, gridColumn: '1/3', p: 2 }}>
          <HeatProgressBar />
        </Box>
      </Box>
      
    )
  }
