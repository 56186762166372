import { UserPermissions } from '@derbytronics/models'
import { SharedCollectionListenerService, useSharedHook } from './SharedListenerService'
import { useMutation } from './UseMutation'
import { FunctionsRootUrl } from './FirebaseConfig'

export type UserCollection = { [ email: string ]: UserPermissions }
export type UserPermissionWithEmail = UserPermissions & { email: string }

class UserService extends SharedCollectionListenerService<UserPermissions> {

  private static singletons: { [eventId: string]: UserService } = {}

  static for(eventId: string): UserService {
    if (!(eventId in this.singletons)) {
      this.singletons[eventId] = new UserService(eventId)
    }
    return this.singletons[eventId]
  }

  private constructor(readonly eventId: string) {
    super(`events/${eventId}/users`)
  }
}

export function useUserList(eventId: string): UserPermissionWithEmail[] {
  return useSharedHook(
    UserService.for(eventId),
    (data) => Object.keys(data).map(email => ({ ...data[email], email })),
    [ eventId ]
  ) || []
}

export function useUser(eventId: string, email?: string): UserPermissions | undefined {
  return useSharedHook(
    UserService.for(eventId),
    (data) => email ? data[email] : undefined,
    [ eventId, email ]
  )
}

export const useCreateUser =
  (eventId: string) =>
    useMutation((auth) => 
      async (email: string) => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/users`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email })
        })
      }
    )

export const useDeleteUser =
  (eventId: string, email: string) =>
    useMutation((auth) => 
      async () => {
        return await fetch(`${FunctionsRootUrl}/web/events/${eventId}/users`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email })
        })
      }
    )
