import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import { FunctionComponent } from 'react'
import { useBrackets, useLastNames } from '../services/RacerService'

type Props = {
  selected: string[]
  onSelect: (selected: string[]) => void
  eventId: string
}

export const GenSelector: FunctionComponent<Props & { title: string, options?: string[] }> = 
({ selected, onSelect, title, options }) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event
    onSelect(typeof value === 'string' ? value.split(',') : value)
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 240 }} size='small'>
      <InputLabel>{title}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        input={<OutlinedInput label={title} />}
        renderValue={(selected) => selected.join(',')}
      >
        { options?.map(name => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selected.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const BracketSelector: FunctionComponent<Props> =
  (props) => {
    const brackets = useBrackets(props.eventId)
    return <GenSelector {...props} options={brackets} title="Brackets" />
  }

export const FamilySelector: FunctionComponent<Props> =
  (props) => {
    const names = useLastNames(props.eventId)
    return <GenSelector {...props} options={names} title="Families" />
  }
