import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, styled, Toolbar } from '@mui/material'
import { FunctionComponent, ReactNode, useState } from 'react'
import { Link, useLinkClickHandler, useParams } from 'react-router-dom'
import { AuthState } from './AuthState'
import { CheckeredFlagIcon } from './CheckeredFlagIcon'
import { StyledName } from './StyledName'

const drawerWidth = 240

export type DrawerItem = {
  name: string
  onClick: () => void | any
}

type Props = {
  drawerItems?: ReactNode | ReactNode[]
  onOpen?: (open: boolean) => void
}

export const Main = styled('main', { shouldForwardProp: (p) => p !== 'open' })<{open?: boolean}>(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen}),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {easing: theme.transitions.easing.easeOut, duration: theme.transitions.duration.enteringScreen}),
      marginLeft: `${drawerWidth}px`
    })
  })
)

export const Header: FunctionComponent<Props> =
  ({ drawerItems, onOpen }) => {
    const [ open, setOpen ] = useState(false)
    const handleOpen = () => {
      onOpen?.(!open)
      setOpen(!open)
    }

    return (
      <Box>
        <AppBar position="fixed" sx={{ zIndex: 1500 }}>
          <Toolbar>
            { !!drawerItems && (
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2, color: 'white' }}
                onClick={ handleOpen }
              >
                <MenuIcon />  
              </IconButton>
            )}
            <Box component={Link} to="/" sx={{ color: 'white', display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
              <CheckeredFlagIcon /><StyledName />
            </Box>
            <Box flex="100%" />
            <AuthState />
          </Toolbar>
        </AppBar>
        { !!drawerItems && (
          <Drawer
          anchor="left"
          variant="persistent"
          open={open}
          sx={{width: drawerWidth, flexShrink: 0, zIndex: 1100, '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' }}}
          >
          <Toolbar />
          <List sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            { drawerItems }
          </List>
          </Drawer>
        )}
      </Box>
    )
  }

const LinkDrawerItem: FunctionComponent<{ name: string, to: string }> =
  ({ name, to }) => {
    const handleClick = useLinkClickHandler(to)

    return (
      <ListItem disablePadding>
        <ListItemButton onClick={(event) => handleClick(event as any)}>
          <ListItemText primary={name}></ListItemText>
        </ListItemButton>
      </ListItem>
    )
  }

export const EventCentricView: FunctionComponent<{}> =
  ({ children }) => {
    const [open, setOpen] = useState(false)
    const params = useParams()
    const eventId = params.eventId as string

    return (
      <Box>
        <Header
          onOpen={setOpen}
          drawerItems={[
            <LinkDrawerItem key="overview" name="Event Overview" to={`/event/${eventId}`} />,
            <LinkDrawerItem key="bullpen" name="Bullpen" to={`/event/${eventId}/bullpen`} />,
            <LinkDrawerItem key="live/board" name="Live Board" to={`/event/${eventId}/live/board`} />,
            <LinkDrawerItem key="live/newBoard" name="New Live Board" to={`/event/${eventId}/live/newBoard`} />,
            <LinkDrawerItem key="live/stats" name="Live Stats" to={`/event/${eventId}/live/stats`} />,

            <ListItem key="spacing"  sx={{ flex: '100%' }} />,

            <LinkDrawerItem key="admin" name="Admin" to={`/event/${eventId}/admin`} />,
          ]}
        />
        <Main open={open}>
          <Toolbar />
          { children }
        </Main>
      </Box>
    )
  }