import { createTheme, Theme } from '@mui/material'

export const theme: Theme =
  createTheme({
    palette: {
      primary: {
        main: '#103261',
        contrastText: 'white'
      },
      secondary: { main: '#c910ab' }
    },
    typography: {
      fontFamily: 'Roboto',
      h1: { fontSize: '3.0em', fontWeight: 400 },
      h2: { fontSize: '2.6em' },
      h3: { fontSize: '2.2em' },
      h4: { fontSize: '1.8em' },
      h5: { fontSize: '1.5em' },
      h6: { fontSize: '1.2em' }
    }
  })