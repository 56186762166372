import { Typography } from "@mui/material";
import { FunctionComponent } from "react";

type Size = 'small' | 'medium' | 'large'

type Props = {
  value?: number | null
  size?: Size
}

const suffixes: { [o: number]: string } = {
  1: 'st',
  2: 'nd',
  3: 'rd'
}

const images: { [o: number]: string } = {
  1: 'https://www.sticker.com/picture_library/product_images/award-ribbons/72430_1st-first-place-award-ribbon-stickers-and-labels.png',
  2: 'https://www.sticker.com/picture_library/product_images/award-ribbons/72435_2nd-second-place-award-ribbon-stickers-and-labels.png',
  3: 'https://www.sticker.com/picture_library/product_images/award-ribbons/72445-3rd-third-place-green-award-ribbon-stickers-and-labels.png'
}

type SizeInfo = {
  imgHeight: number
  variant: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "subtitle1" | "subtitle2" | "body1" | "body2" | "overline" | undefined
}

const SizeInfos: { [size: string]: SizeInfo } = {
  small: { imgHeight: 24, variant: undefined },
  medium: { imgHeight: 65, variant: 'h6' },
  large: { imgHeight: 150, variant: 'h3' }
}

export const Place: FunctionComponent<Props> =
  ({ value, size }) => {
    const sizeInfo = SizeInfos[size || 'medium']

    if (!value) { return null }
    if (value in images) {
      return (<img style={{ maxHeight: sizeInfo.imgHeight }} alt={`Place #${value} Ribbon`} src={images[value]} />)
    }
    let suffix = 'th'
    let ones = value % 10
    if (value < 10 && value > 20 && (ones in suffixes)) {
      suffix = suffixes[ones]
    }
    return (sizeInfo.variant ? <Typography variant={sizeInfo.variant}>{value}{suffix}</Typography> : <span>{value}{suffix}</span>)
  }