import { DeleteForever } from '@mui/icons-material'
import { DialogContentText, IconButton } from '@mui/material'
import { FunctionComponent, useState } from 'react'
import { useDeleteRacer } from '../../services/RacerEditService'
import { RacerWithId } from '../../services/RacerService'
import { Form } from '../forms/Form'

type Props = {
  disabled?: boolean
  eventId: string
  racer: RacerWithId
}

export const DeleteRacer: FunctionComponent<Props> =
  ({disabled, eventId, racer}) => {
    const [ open, setOpen ] = useState(false)
    const { loading, submit } = useDeleteRacer(eventId, racer.id)

    return (
      <>
        <IconButton disabled={disabled} color="error" onClick={() => setOpen(true)}>
          <DeleteForever />
        </IconButton>
        <Form
          open={open}
          onClose={() => setOpen(false)}
          loading={loading}
          title={`Delete ${racer.carName}?`}
          submit={submit}
          submitText="Delete"
          destructive
        >
          <DialogContentText>
            Are you sure you want to delete {racer.firstName} {racer.lastName}'s "{racer.carName}"?
            This is a permenant action and cannot be undone. This will delete their information
            as well as the associated image.
          </DialogContentText>
        </Form>
      </>
    )
  }