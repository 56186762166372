import { Racer as RacerModel } from '@derbytronics/models'
import { useParams } from 'react-router-dom'
import { useHeats } from './HeatsService'
import { SharedCollectionListenerService, useSharedHook } from './SharedListenerService'

export type RacerCollection = { [id: string]: RacerModel }
export type RacerWithId = RacerModel & { id: string }

class RacerService extends SharedCollectionListenerService<RacerModel> {

  private static singletons: { [eventId: string]: RacerService } = {}

  static for(eventId: string): RacerService {
    if (!(eventId in this.singletons)) {
      this.singletons[eventId] = new RacerService(eventId)
    }
    return this.singletons[eventId]
  }

  private constructor(readonly eventId: string) {
    super(`events/${eventId}/racers`)
  }

}

export function useRacer(eventId: string, racerId: string): RacerModel | undefined {
  return useSharedHook(
    RacerService.for(eventId),
    (data) => data[racerId],
    [ racerId ]
  )
}

export function useRacers(eventId: string, racerIds: (string | null)[] | undefined): (RacerModel | null)[] | undefined {
  return useSharedHook(
    RacerService.for(eventId),
    (data) => racerIds?.map(i => i ? data[i] : null),
    [ racerIds ]
  )
}

type RacerSorter = (a: RacerWithId, b: RacerWithId) => number

function toSortable(r: RacerWithId): number {
  return r.averageTime?.value || (10000 + Math.abs(hashCode(r.carName)))
}

export const ResultTimeRacerSorter: RacerSorter = (a, b) => toSortable(a) - toSortable(b)
export const LexographicalRacerSorter: RacerSorter =
  (a, b) => {
    if (a.bracket === b.bracket) {
      if (a.firstName < b.firstName) {
        return -1
      } else if (a.firstName === b.firstName) {
        return 0
      } else {
        return 1
      }
    } else if (a.bracket < b.bracket) {
      return -1
    } else {
      return 1
    }
  }

export const LastNameRacerSorter: RacerSorter =
  (a, b) => {
    if (a.lastName < b.lastName) {
      return -1
    } else if (a.lastName === b.lastName) {
      return 0
    } else {
      return 1
    }
  }

export function useRacerList(eventId: string, sorter: RacerSorter = ResultTimeRacerSorter): RacerWithId[] | undefined {
  return useSharedHook(
    RacerService.for(eventId),
    (data) => Object.keys(data).map(id => ({ ...data[id], id })).sort(sorter)
  )
}

export function useRacerCollection(eventId: string): RacerCollection | undefined {
  return useSharedHook(
    RacerService.for(eventId),
    (data) => data
  )
}


function hashCode(s: string): number {
  let hash = 0, i, chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr   = s.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function uniqueUseFunction(extractor: (r: RacerModel) => string): (eventId: string) => string[] | undefined {
  return (eventId: string) => {
    return useSharedHook(
      RacerService.for(eventId),
      (data) => Object.keys(Object.values(data).reduce((kv, r) => ({ ...kv, [extractor(r)]: 1 }), {} as {[k: string]: number})).sort(),
      [eventId]
    )
  }
}

export const useBrackets: (eventId: string) => string[] | undefined = uniqueUseFunction(r => r.bracket)
export const useLastNames: (eventId: string) => string[] | undefined = uniqueUseFunction(r => r.lastName)

export function useRacerId(): string {
  const params = useParams()
  return params.racerId as string
}

export type RacerScheduledHeat = {
  heatId: string
  heatOrder: number
  lane: number
  done: boolean
}

export function useRacerSchedule(eventId: string, racerId: string): RacerScheduledHeat[] {
  const heats = useHeats(eventId)
  if (!heats) { return [] }

  return heats
    .filter(h => h.racers.includes(racerId))
    .map(h => ({
      heatId: h.id,
      heatOrder: h.order,
      lane: h.racers.indexOf(racerId),
      done: h.done
    }))
}
