import { Heat, Racer } from '@derbytronics/models'
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { EventCentricView } from '../components/Header'
import { useNextHeat } from '../services/HeatsService'
import { useRacers } from '../services/RacerService'

type Props = {
  title: string
  heat: Heat
  racers: (Racer | null)[]
}

const HeatUp: FunctionComponent<Props> = 
  ({ title, heat, racers }) => (
    <Box>
      <Paper sx={{ m: 1, p: 2, display: "inline-block" }}>
        <Typography variant="h5">{title} Heat #{heat.order + 1}</Typography>
        <Table sx={{ width: "inherit" }} size="small" >
          <TableHead>
            <TableRow>
              <TableCell sx={{width: "25px"}} align="right">Lane</TableCell>
              <TableCell sx={{width: "50px"}} align="center">Label</TableCell>
              <TableCell sx={{maxWidth: "200px"}}>Car Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { heat.racers.map((id, lane) =>
              <TableRow key={lane}>
                <TableCell sx={{width: "25px"}} align="right">{lane + 1}</TableCell>
                <TableCell sx={{width: "50px"}} align="center">{ racers[lane]?.label }</TableCell>
                <TableCell sx={{width: "200px"}}>{ id && racers[lane]?.carName }</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  )

export const BullpenPage: FunctionComponent<{}> =
  () => {
    const params = useParams()
    const eventId = params.eventId as string
    const [ next, onDeck ] = useNextHeat(eventId, 2)
    const nextRacers = useRacers(eventId, next?.racers)
    const onDeckRacers = useRacers(eventId, onDeck?.racers)

    return (
      <EventCentricView>
        { next && nextRacers && <HeatUp title="Next Up" heat={next} racers={nextRacers} /> }
        { onDeck && onDeckRacers && <HeatUp title="On Deck" heat={onDeck} racers={onDeckRacers} /> }
      </EventCentricView>
    )
  }
